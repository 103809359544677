import NewPositionLayout from "../../components/Layout";
import {NavigationActions} from "../../components/NavigationActions";
import {Typography} from "antd";
import s from './ReviewAndEdit.module.css'
import {useMemo} from "react";
import {reviewAndEdit} from "../../../../../static/staticData";

import {ReviewItem} from "../ReviewItem";

const {Title} = Typography;

export const ReviewAndEdit = () => {

    const inputFields = useMemo(() => (
        reviewAndEdit.map(el => (
            <ReviewItem key={el.id} {...el}/>
        ))
    ), [])

    return (
        <NewPositionLayout
            navigationElements={<NavigationActions/>}
            progress={60}
        >
            <Title level={2} style={{fontWeight: 'bold'}}>
                Review and Edit
            </Title>

            <div className={s.container}>
                {inputFields}
            </div>
        </NewPositionLayout>
    );
};

