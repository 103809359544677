import {Button, Layout, Input, Typography} from 'antd';
import Header from 'components/shared/Header';
import React, {useEffect, useState} from 'react';
import CompanyCard from 'components/CompanyCard';
import {GoogleSearchCompany} from 'features/companies/types';
import {
    autocompleteCity,
    registerNewCompany,
    searchCompanies
} from 'features/companies';
import {useNavigate} from 'react-router-dom';
import ReviewCompany from './CompanySearch/ReviewCompany/ReviewCompany';

import {ReactComponent as LocationIcon} from '../assets/images/icons/location.svg';
import {ReactComponent as SearchIcon} from '../assets/images/icons/search.svg';
import {SpinnerBlock} from "../components/shared/SpinnerBlock";

const {Content} = Layout;

const CompanySearchScreen = () => {
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [location, setLocation] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [cities, setCities] = useState<string[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [searchResults, setSearchResults] = useState<GoogleSearchCompany[]>();
    const [isEditing, setIsEditing] = useState(false);
    const [editData, setEditData] = useState<any>(undefined);
    const handleInputChange = async (inputVal: string) => {
        setLocation(inputVal);
        if (inputVal.length > 0) {
            try {
                let {data} = await autocompleteCity(inputVal)
                if (data) {
                    const cities: string[] = data;
                    const filteredCities = cities.filter(city =>
                        city.toLowerCase().includes(inputVal.toLowerCase())
                    );
                    setCities(filteredCities);
                    setShowDropdown(true);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setCities([]);
            setShowDropdown(false);
        }
    };

    const handleCitySelect = (selectedCity: string) => {
        setLocation(selectedCity);
        setShowDropdown(false);
    };

    const handleReviewCompanyData = async (updatedData: any) => {

        const mergedData = {...editData, ...updatedData};

        setEditData(mergedData);
        setTimeout(() => {
            navigate('/home')
        }, 1000);
        try {
            await registerNewCompany(mergedData);

        } catch (error) {
            console.error('Error while updating company:', error);
            alert('Error while updating company');
        }
    };
    const handleCancelEditing = () => {
        setEditData(undefined);
        setIsEditing(false);
    }

    const findCompanies = async () => {
        setLoading(true);
        try {
            const resp = await searchCompanies(search, location);
            setSearchResults(resp);
            setLoading(false);
        } catch (e) {
            console.error('Error while searching companies:', e);
        }
    };

    useEffect(() => {
        if (!search && !location) {
            setSearchResults([]);
        }
    }, [search, location]);

    return (
        <Layout className="layout" style={{backgroundColor: 'white'}}>
            <Header/>
            <Content style={{padding: '0 40px', minHeight: 'calc(100vh - 144px)'}}>
                {isEditing
                    ? (
                        isGenerating
                            ? <SpinnerBlock title="Loading"/>
                            : (
                                <ReviewCompany
                                    editData={editData}
                                    onSave={handleReviewCompanyData}
                                    onCancel={handleCancelEditing}
                                />
                            )
                    )
                    : (
                    <div
                        style={{
                            padding: 40,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <Typography.Title level={2} style={{marginBottom: 40}}>
                                Let&#x2019;s find your business!
                            </Typography.Title>
                            <div style={{display: 'flex', marginBottom: 35, gap: 10}}>
                                <Input
                                    placeholder="Enter city or state"
                                    value={location}
                                    onChange={(e) => handleInputChange(e.target.value)}
                                    prefix={<LocationIcon/>}
                                    style={{width: 247}}
                                />
                                <Input
                                    placeholder="Enter business name"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    style={{width: 545}}
                                />
                                <Button
                                    type="primary"
                                    disabled={!search.length || !location.length}
                                    shape='round'
                                    size="large"
                                    onClick={findCompanies}
                                    loading={isLoading}
                                    icon={<SearchIcon/>}
                                    style={{
                                        display: 'flex',
                                        padding: '10px 16px',
                                        fontSize: 16,
                                        alignItems: 'center',
                                    }}
                                >
                                    Search
                                </Button>
                            </div>
                            {showDropdown && !!cities.length
                              && (
                                <div
                                    style={{
                                        maxHeight: 550,
                                        width: 247,
                                        overflowY: 'auto',
                                        borderRadius: 8,
                                        position: 'absolute',
                                        zIndex: 2,
                                        border: '1px solid var(--Divider, #EAEDF2)',
                                        boxShadow: '0px 4px 18px 0px rgba(147, 149, 153, 0.10)',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <ul style={{listStyleType: 'none', padding: 0}}>
                                        {cities.map((city, index) => (
                                            <li
                                                key={index}
                                                style={{
                                                    padding: '8px 12px',
                                                    cursor: 'pointer',
                                                    transition: 'background-color 0.3s',
                                                }}
                                                onClick={() => handleCitySelect(city)}
                                                onMouseEnter={(e) => (
                                                    e.target as HTMLLIElement
                                                ).style.backgroundColor = '#F6F8FA'}
                                                onMouseLeave={(e) => (
                                                    e.target as HTMLLIElement
                                                ).style.backgroundColor = 'white'}
                                            >
                                                {city}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                                {searchResults && searchResults.length > 0 && (
                                    <div
                                        style={{
                                            display: 'grid',
                                            gap: 16,
                                            overflowY: 'auto',
                                            padding: '0 7px'
                                        }}
                                    >
                                        {searchResults.map((result, index) => (
                                            <CompanyCard
                                                setIsGenerating={setIsGenerating}
                                                setIsEditing={setIsEditing}
                                                setEditData={setEditData}
                                                key={result.place_id}
                                                option={result}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
            </Content>
        </Layout>
    );
};

export default CompanySearchScreen;
