import { Layout, Typography } from 'antd';

import Sidebar from 'components/shared/Sidebar';

import NavigationRow from './NavigationRow';

import BusinessesList from './BusinessesList';
import {CSSProperties, useEffect} from 'react';
import {useActions} from "../../hooks/useActions";
import {isUserAuthorized} from "../../features/auth/model";

const { Content } = Layout;
const { Title } = Typography;

const containerStyle: CSSProperties = {
  minHeight: '100vh',
};

const contentStyle: CSSProperties = {
  color: '#fff',
  backgroundColor: 'white',
  padding: '0 36px 64px 36px',
  maxHeight: '100vh',
  overflowY: 'auto',
};

const Home = () => {
    const {logIn} = useActions()
    const getInfoAboutUser = async () => {
        try {
            const {data} = await isUserAuthorized()
            logIn(data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getInfoAboutUser()
        // eslint-disable-next-line
    }, [])

  return (
    <Layout className='layout' style={containerStyle}>
      <Sidebar />
      {/*TODO: check inner layout*/}
      <Layout>
        <Content style={contentStyle}>
          <div
            style={{
              height: 196,
              margin: '0 -36px',
              background:
                'conic-gradient(from 198deg at 60.51% 68.21%, #3253DC 0deg, #552BCE 360deg), url(<path-to-image>), lightgray 50% / cover no-repeat',
            }}
          ></div>
          {/*<ColorHeader color={HeaderLayoutColor.blue} height={190} />*/}

          <div style={{ marginTop: -184, paddingTop: 34 }} className='position-container'>
            <Title style={{ position: 'relative', color: 'white' }} level={1}>
              Welcome!
            </Title>
            <NavigationRow />
            <BusinessesList />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
