import { useEffect, useState } from 'react';
import {Card, Layout, Modal} from 'antd';
import Sidebar from '../../../../../components/shared/Sidebar';
import Header from '../../components/Layout/Header';
import {deletePosition, FullPosition} from 'features/positions';
import {
  ColorHeader,
  HeaderLayoutColor,
} from '../../../../../components/shared/ColorHeader';
import s from './PositionView.module.css';
import { PositionsTabs } from '../PositionsTabs';
import PositionsController from '../../../../../features/positions/PositionsController';
import {useNavigate, useParams} from 'react-router-dom';
import { CompanyCard } from '../../components/CompanyCard';
import {TabsValue} from "../../../../../static/staticData";

const { Content } = Layout;

const PositionView = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [position, setPosition] = useState<FullPosition | null>(null);

  const [currentColor, setCurrentColor] = useState<HeaderLayoutColor>(
    HeaderLayoutColor.blue,
  );

  const [currentVar, setCurrentVar] = useState<TabsValue>(TabsValue.original)

  const [deleting, setDeleting] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (uuid) {
      (async function () {
        const response = await PositionsController.getPosition(uuid);
        response && setPosition(response);
      })();
    }
  }, [uuid]);

  const showDeleteModal = () => {
    setDeleting(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    if (position) {
      try {
        await deletePosition(position.id);
      } catch (error) {
        console.error("Error deleting position:", error);
      } finally {
        setConfirmLoading(false);
        setDeleting(false);
        navigate("/positions");
      }
    }
  };

  const handleCancel = () => {
    setDeleting(false);
  };

  return (
    <Layout className={s.layout}>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{height: "calc(100vh - 67px)", overflowY: "auto"}}>
          <ColorHeader color={currentColor} />
          {position && (
            <div className='position-container'>
              <CompanyCard company={position.company} position={position} onDelete={showDeleteModal} currentVar={currentVar}/>
              <Card className={s.card} bodyStyle={{ padding: 0 }}>
                <PositionsTabs
                  setCurrentColor={setCurrentColor}
                  setCurrentVar={setCurrentVar}
                  item={position}
                />
              </Card>
            </div>
          )}
        </Content>
      </Layout>
      <Modal
          title="Are you sure you want to delete the position?"
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          open={deleting}
          onOk={handleOk}
          centered
      >
        <p>All information will be deleted!</p>
      </Modal>
    </Layout>
  );
};

export default PositionView;
