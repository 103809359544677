import React, { FC } from "react";

export enum TitleEnum {
    responsibilities = 'What you get to do',
    benefits = 'Benefits',
    requirements = 'Requirements',
    description = "About",
    education = "Education",
    certification = "Certification"
}

type Props = {
    title: TitleEnum
    content: string
}

export const TextReflector: FC<Props> = (props) => {
    const { title, content } = props;

    let formattedContent;

    if (content) {
        formattedContent = content.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    } else {
        formattedContent = '';
    }

    return (
        <div>
            <h3 className='text-reflector-details-title'>{title}</h3>
            <p className='text-reflector-details-text'>{formattedContent}</p>
        </div>
    );
};
