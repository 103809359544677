import {Form, FormListFieldData, Input} from "antd";
import {FC} from "react";
import {ReactComponent as TrashCanIcon} from '../../../../../assets/images/icons/trash-can.svg';
import s from './PositionSkill.module.css'
import {FieldLimits} from "../../../../../static/staticData";

type Props = {
    field: FormListFieldData
    removeSkillHandler: (arg: number) => void
}

export const PositionSkill:FC<Props> = (props) => {

    const {field, removeSkillHandler} = props

    return (
        <div className={s.container}>
            <Form.Item
                style={{marginBottom: 0}}
                rules={[
                    { required: true, message: `Please enter skill!` },
                    { max: FieldLimits.positionSkills, message: `Skill cannot be longer than ${FieldLimits.positionSkills} characters`}
                ]}
                {...field}
            >
                <Input
                    style={{
                        borderBottom: '1px solid #EAEDF2',
                        borderRadius: 0,
                    }}
                    bordered={false}
                />
            </Form.Item>
                <TrashCanIcon
                    className={s.icon}
                    onClick={() => removeSkillHandler(field.name)}
                />
            </div>
    );
};

