import { Layout, Typography } from 'antd';

import Header from 'components/shared/Header';
import SignInButton from './SignInButton';
import useSsoAuth from './useSsoAuth';

import googleIcon from 'assets/images/icons/brand/google.svg';
import outlookIcon from 'assets/images/icons/brand/outlook.svg';

const { Content } = Layout;
const { Title, Text } = Typography;

const SignInScreen = () => {
  const { authLinks } = useSsoAuth();

  return (
    <Layout className='layout' style={{ backgroundColor: 'white' }}>
      <Header logoLabel='business' />
      <Content style={{ padding: '0 40px', minHeight: 'calc(100vh - 144px)' }}>
        <div
          style={{
            padding: 40,
            marginTop: 128,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifySelf: 'center',
            maxWidth: 740,
            margin: '0 auto',
          }}
        >
          <Title level={3}>Continue to your SchoolJoy Business page</Title>
          <div
            style={{
              width: '100%',
              margin: 28,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <SignInButton
              icon={<img src={googleIcon} alt='google' />}
              role='link'
              href={authLinks.google}
            >
              Sign In with Google
            </SignInButton>
            <SignInButton
              icon={<img src={outlookIcon} alt='microsoft' />}
              role='link'
              href={authLinks.microsoft}
            >
              Sign In with Outlook
            </SignInButton>
          </div>
          <Text type='secondary' style={{ fontSize: 13 }}>
            By continuing, you agree to SchoolJoy&#x2019;s{' '}
            <a href='/'>Terms and Conditions</a>. You also acknowledge our{' '}
            <a href='/'>Privacy Policy</a>.
          </Text>
        </div>
      </Content>
    </Layout>
  );
};

export default SignInScreen;
