import {Button, Card, Tag, Typography} from 'antd';
import locationIcon from '../assets/images/icons/location.svg'
import {generateCompany, GoogleSearchCompany} from "../features/companies";

const {Title} = Typography

interface Props {
    option: GoogleSearchCompany;
    setIsEditing: (arg: boolean) => void
    setIsGenerating: (arg: boolean) => void
    setEditData: (arg: any) => void

}

const CompanyCard = (props: Props) => {
    const {
        option,
        setIsEditing,
        setEditData,
        setIsGenerating
    } = props

    const claimBusiness = async () => {
        const payload = {
            name: option.title,
            location: option.address || 'Address not provided',
            place_id: option.place_id,
            phone: option.phone,
            link: option.links?.website,
            thumbnail: option.thumbnail || '',
            brand_identity: '',
            description: option.description || option.type || 'No description',
            gps_coordinates: option.gps_coordinates,
            members: [],
        };
        setIsEditing(true);
        setIsGenerating(true);

        try {
            const {data} = await generateCompany(payload);
            setEditData(data);
        } catch (e) {
            console.log(e)
        }
        setIsGenerating(false);
    };

    return (
          <Card hoverable style={{ position: 'relative' }}>
            <Title
              level={5}
              style={{
                color: 'darkslategray',
                margin: 0,
                marginBottom: 16,
              }}
              aria-label={option.title}
            >
              {option.title}
            </Title>
            {option.claimed && (
              <Tag
                color='lightgrey'
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 10,
                  color: 'black',
                    borderRadius: 8,
                }}
              >
                Claimed
              </Tag>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                marginBottom: 20,
              }}
            >
              <img src={locationIcon} alt="location icon" />
              <span style={{ color: 'gray' }}>{option.address}</span>
            </div>
            <p style={{ marginBottom: 20, width: '90%' }}>
              {option.description}
            </p>

            <Button
              onClick={claimBusiness}
              style={{ width: 'fit-content' }}
              disabled={option.claimed}
              type='primary'
            >
              Claim Business
            </Button>
          </Card>
        );
};

export default CompanyCard;
