import { FC, useEffect } from "react";
import { Progress } from "antd";
import Typography from "antd/es/typography";


const { Title, Text } = Typography;

type Props = {
  percent: number;
  setPercent: (arg: number) => void;
};
export const LinearProgress: FC<Props> = (props) => {

  const { percent, setPercent } = props;
  useEffect(() => {
    setTimeout(() => {
      setPercent(10);
    }, 1000);
    setTimeout(() => {
      setPercent(20);
    }, 2000);
    setTimeout(() => {
      setPercent(30);
    }, 3000);
    setTimeout(() => {
      setPercent(40);
    }, 4000);
    setTimeout(() => {
      setPercent(50);
    }, 5000);
    setTimeout(() => {
      setPercent(60);
    }, 6000);
    setTimeout(() => {
      setPercent(70);
    }, 7000);
    setTimeout(() => {
      setPercent(80);
    }, 8000);
    setTimeout(() => {
      setPercent(90);
    }, 9000);
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{
      textAlign: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }}>
      <Title level={4}>We're currently creating the position</Title>
      <Text>Wait a few minutes, please</Text>
      <Progress percent={percent} showInfo={false} />
    </div>
  );
};
