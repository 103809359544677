import React, {FC, useState} from "react";
import {Button, Form, Input, Modal} from "antd";
import {PositionSkill} from "../../../pages/CompanySearch/Positions/CreateNewPosition/PositionSkill";
import {PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

import s from "./EditPositionContent.module.css";
import PositionsController from "../../../features/positions/PositionsController";
import {FieldLimits} from "../../../static/staticData";

interface PositionContentProps {
    form: any;
    positionId: string;
    label: string;
}

const EditPositionContent: FC<PositionContentProps> = (props) => {
    const navigate = useNavigate()
    const { form, positionId, label } = props

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // eslint-disable-next-line
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = async () => {
        navigate(-1);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCancelEditing = () => {
        if (form.isFieldsTouched()) {
            setIsModalOpen(true);
        } else {
            navigate(-1);
        }
    };

    const handleSaveChanges = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldValue("result");

            const { type, age_requirement, currently_hiring, high_demand, dynamic_fields } = values;

            setLoading(true);

            const updatedPositionData = {
                type,
                age_requirement,
                currently_hiring,
                high_demand,
                dynamic_fields,
            };

            await PositionsController.updatePositionData(positionId, updatedPositionData);
            setLoading(false);
            navigate(-1);

        } catch (errorInfo) {
            console.error("Error while updating position", errorInfo);
        }
    };

    const attributes = [
        { key: "title", label: "Name", maxWidth: FieldLimits.positionName },
        { key: "responsibilities", label: "Responsibilities", maxWidth: FieldLimits.positionDescription },
        { key: "requirements", label: "Requirements", maxWidth: FieldLimits.positionDescription },
        { key: "benefits", label: "Benefits", maxWidth: FieldLimits.positionDescription },
        { key: "education", label: "Education", maxWidth: FieldLimits.positionEducation },
        { key: "certification", label: "Certifications", maxWidth: FieldLimits.positionCertification },
    ];

    return (
        <>
            <div className={s.header}>
                Edit Position
            </div>

            {attributes.map((attribute) => (
                <div key={attribute.key}
                     style={{
                         display: "flex",
                         width: "100%",
                         flexDirection: "column",
                         gap: 10
                    }}
                >
                    <span className={s.title}>{attribute.label}</span>
                    <Form.Item
                        name={["result", "dynamic_fields", `${label}`, attribute.key]}
                        rules={[
                            { required: true, message: `Please enter variation ${attribute.label.toLowerCase()}!` },
                            { max: attribute.maxWidth, message: `${attribute.label} cannot be longer than ${attribute.maxWidth} characters`},
                        ]}
                    >
                        <Input.TextArea
                            showCount
                            maxLength={attribute.maxWidth}
                            placeholder={`Enter ${attribute.label.toLowerCase()} for ${label}`}
                            autoSize={true}
                            bordered={false}
                            className={s.inputField}
                        />
                    </Form.Item>
                </div>
            ))}
            <span className={s.title}>Skills</span>
            <Form.List name={["result", "dynamic_fields", `${label}`, "skills"]}>
                {(fields, {add, remove}) => (
                    <div style={{marginTop: 10}}>
                        {fields.map((field) => (
                            <PositionSkill
                                removeSkillHandler={remove}
                                key={field.key}
                                field={field}
                            />
                        ))}
                        <Button type='link' onClick={() => add()}>
                            <PlusOutlined/>
                            Add Skill
                        </Button>
                    </div>
                )}
            </Form.List>

            <div style={{ display: "flex", gap: 16, justifyContent: "flex-end", marginTop: 24 }}>
                <Button type="default"
                        htmlType="button"
                        onClick={handleCancelEditing}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                >
                    Cancel
                </Button>
                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="button"
                        onClick={handleSaveChanges}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                    >
                        Save changes
                    </Button>
                </Form.Item>
            </div>
            <Modal
                title="You Have Unsaved Changes"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                open={isModalOpen}
                onOk={handleOk}
                centered
            >
                <p>You have unsaved changes, while editing information. Unsaved changes will be lost.</p>
            </Modal>
        </>
    );
};

export default EditPositionContent;
