import { Button, ButtonProps } from 'antd';

const SignInButton = ({ href, children, icon }: ButtonProps) => {
  return (
    <Button
      type='default'
      size='large'
      style={{
        padding: 21,
        width: '100%',
        maxWidth: 460,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        borderRadius: 24,
      }}
      role='link'
      href={href}
    >
      {icon}
      <span style={{ fontSize: 15 }}>{children}</span>
    </Button>
  );
};

export default SignInButton;
