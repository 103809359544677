import { createBrowserRouter } from 'react-router-dom';

import Home from 'pages/CompanySearch/Home';
import SignInScreen from 'pages/SignInScreen';
import CompanySearchScreen from 'pages/CompanySearchScreen';
import PositionView from "./pages/CompanySearch/Positions/PositionDetails/PositionView/PositionView";
import Companies from "./pages/Companies/Companies";
import CompanyDetails from "./pages/CompanyDetails/CompanyDetails";
import Analytics from "./pages/Analytics/Analytics";
import CreateNewPosition from "./pages/CompanySearch/Positions/CreateNewPosition/CreateNewPosition";
import EditCompany from "./components/CompanyDetails/EditCompany/EditCompany";
import PositionsPage from "./pages/CompanySearch/Positions/Positions/PositionsPage";
import EditPosition from "./components/PositionDetails/EditPosition/EditPosition";
import CompanyAnalytics from "./pages/Analytics/CompanyAnalytics/CompanyAnalytics";
import PositionAnalytics from "./pages/Analytics/PositionAnalytics/PositionAnalytics";

export const router = createBrowserRouter([
  {
    path: 'home',
    element: <Home />,
  },
  {
    path: 'positions',
    children: [
      {
        index: true,
        element: <PositionsPage />,
      },
      {
        path: 'new',
        element: <CreateNewPosition />,
      },
      {
        path: 'edit/:uuid',
        element: <EditPosition /> ,
      },
      {
        path: ':uuid',
        element: <PositionView />,
      },
    ],
  },
  {
    path: 'companies',
    children: [
      {
        index: true,
        element: <Companies />,
      },
      {
        path: 'edit/:uuid',
        element: <EditCompany /> ,
      },
      {
        path: ':uuid',
        element: <CompanyDetails />,
      },

    ],
  },
  {
    path: 'analytics',
    children: [
      {
        index: true,
        element: <Analytics />,
      },
      {
        path: 'companies/:uuid',
        element: <CompanyAnalytics /> ,
      },
      {
        path: 'positions/:uuid',
        element: <PositionAnalytics />,
      },
    ],
  },
  {
    path: 'search',
    element: <CompanySearchScreen />,
  },
  {
    path: '/:code?',
    element: <SignInScreen />,
  },
  {
    path: '*',
    element: <h1>404</h1>,
  },
]);
