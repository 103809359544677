import { Layout } from "antd";
import Sidebar from "components/shared/Sidebar";
import Header from "./Header";
import Progress from "./Progress";
import styles from "./Layout.module.css";
import { ReactNode } from "react";

const { Content, Footer } = Layout;

export const CONTENT_WIDTH = 692;

interface Props {
  children: ReactNode;
  navigationElements: ReactNode;
  progress?: number;
}

const NewPositionLayout = ({ ...props }: Props) => {
  return (
    <Layout className={styles.Layout} hasSider>
      <Sidebar />
      <Header />
      <Content className={styles.Content}>
        <div
          style={{
            width: CONTENT_WIDTH,
            margin: "0 auto"
          }}
        >
          {props.children}
        </div>
      </Content>
      <Footer
        style={{ width: CONTENT_WIDTH }}
        className={styles.Footer}
      >
        <Progress percent={props.progress} />
        {props.navigationElements}
      </Footer>
    </Layout>
  );
};

export default NewPositionLayout;
