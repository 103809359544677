import { useNavigate } from 'react-router-dom';
import { Button, Layout } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { BasicProps } from 'antd/es/layout/layout';

const { Header } = Layout;

interface CustomHeaderProps extends BasicProps {
    color?: string;
    borderColor?: string;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({ color = 'white', borderColor, ...props }) => {
    const navigate = useNavigate();

    const headerStyle: React.CSSProperties = {
        gridArea: 'header',
        height: 68,
        paddingInline: 20,
        backgroundColor: color,
        borderBottom: borderColor ? (`1px solid ${borderColor}`) : ("none"),
        zIndex: 10,
    };

    return (
        <Header style={headerStyle} {...props}>
            <Button
                onClick={() => navigate(-1)}
                type='link'
                style={{
                    color: color === 'white' ? 'var(--colorTextSecondary)' : "white",
                }}
            >
                <LeftOutlined />
                Back
            </Button>
        </Header>
    );
};

export default CustomHeader;
