import { Progress, ProgressProps } from 'antd';
import { CONTENT_WIDTH } from '.';

const CustomProgress = ({ percent, ...props }: ProgressProps) => {
  const steps = 5;
  const stepLength = CONTENT_WIDTH / steps - 2;

  return (
    <Progress
      percent={percent}
      steps={steps}
      size={[stepLength, 6]}
      showInfo={false}
      {...props}
    />
  );
};

export default CustomProgress;
