import { FC } from 'react';
import s from './SpinnerBlock.module.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
    title?: string;
    info?: string;
};

export const SpinnerBlock: FC<Props> = ({ title, info }) => {
    return (
        <div className={s.loading}>
            {title && <p className={s.title}>{title}</p>}
            {info && <p className={s.info}>{info}</p>}
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
    );
};
