import { Card, Space, Typography, theme } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BuildingIcon } from 'assets/images/icons/building.svg';
import { ReactComponent as SuitcaseIcon } from 'assets/images/icons/suitcase.svg';
import { ReactComponent as ReportsIcon } from 'assets/images/icons/reports.svg';
import { CSSProperties, FC, SVGProps } from 'react';

const { Title, Text } = Typography;

const { useToken } = theme;

const cardStyle: CSSProperties = {
  height: 230,
};

interface NavigationItemProps {
  title: string;
  description: string;
  navigateTo: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

const NavigationItem = (props: NavigationItemProps) => {
  const { token } = useToken();
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(props.navigateTo)}
      hoverable
      style={cardStyle}
    >
      <props.icon fill={token.colorPrimary} width={48} height={48} />
      <Title level={3}>{props.title}</Title>
      <Text>{props.description}</Text>
    </Card>
  );
};

const NavigationRow = () => {
  return (
    <Space
      size={16}
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
      }}
    >
      <NavigationItem
        title='Businesses'
        description="View list of your claimed businesses."
        icon={BuildingIcon}
        navigateTo={'/companies'}
      />
      <NavigationItem
        title='Positions'
        description='Easily add and oversee job positions for each of your businesses.'
        icon={SuitcaseIcon}
        navigateTo={'/positions'}
      />
      <NavigationItem
        title='Analytics'
        description='Get a deeper understanding of what piques student interest.'
        icon={ReportsIcon}
        navigateTo={'/analytics'}
      />
    </Space>
  );
};

export default NavigationRow;
