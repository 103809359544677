import {FC, useMemo} from "react";
import s from './Skills.module.css'

type Props = {
    skills: string[]
}

export const Skills:FC<Props> = (props) => {

    const {skills} = props

    const items = useMemo(() => (
        skills.map(skill => (
            <div className={s.skill} key={skill}>
                {skill}
            </div>
        ))
        // eslint-disable-next-line
    ),[])

    return (
        <div className={s.container}>
            <h3 className='text-reflector-details-title'>Skills</h3>
            <div className={s['items-container']}>
                {items}
            </div>
        </div>
    );
};

