export enum TabsValue {
    original = 'origin',
    elementary = 'elementary',
    middle = 'middle',
    high = 'high'
}

export enum FieldLimits {
    companyName = 50,
    companyShortDescription = 250,
    companyDescription = 3000,
    positionName = 150,
    positionSkills = 150,
    positionDescription = 3000,
    positionEducation = 2000,
    positionCertification = 2000,
    link = 100,
    phone = 50,
    address = 70,
    brandIdentity = 2000,
}

export type PositionTabs = {
    key: number
    label: string
    value: string
}

export const TabsData = [
    {key: 0, label: 'Original'},
    {key: 1, label: 'Elementary'},
    {key: 2, label: 'Middle School'},
    {key: 3, label: 'High School'},
]

export type ReviewItemType = {
    id: number
    title: string
    names: string[]
    itemRule: any
    placeholder: string
    max: number
}


export const formFields = [
    {
        name: ["location", "city"],
        title: "City",
        placeholder: "City",
        rules: [{max: 10, message: 'State cannot be longer than 10 characters'}]
    },
    {
        name: ["location", "state"],
        title: "State",
        placeholder: "State",
        rules: [{max: 10, message: `State cannot be longer than 10 characters`}]
    },
    {
        name: ["location", "zipCode"],
        title: "Zip Code",
        placeholder: "Zip Code",
        rules: [{max: 10, message: 'Address cannot be longer than 10 characters'}]
    }
];

export const reviewAndEdit: ReviewItemType[] = [
    {
        id: 1,
        title: 'Position Title',
        names: ['results', 'title'],
        itemRule: {
            max: FieldLimits.positionName,
            message: `Position Title cannot be longer than ${FieldLimits.positionName} characters`
        },
        placeholder: '',
        max: FieldLimits.positionName
    },
    {
        id: 2,
        title: 'Responsibilities',
        names: ['results', 'responsibilities'],
        itemRule: {
            max: FieldLimits.positionDescription,
            message: `Responsibilities cannot be longer than ${FieldLimits.positionDescription} characters`
        },
        placeholder: '',
        max: FieldLimits.positionDescription
    },
    {
        id: 3,
        title: 'Requirements',
        names: ['results', 'requirements'],
        itemRule: {
            max: FieldLimits.positionDescription,
            message: `Requirements cannot be longer than ${FieldLimits.positionDescription} characters`
        },
        placeholder: '',
        max: FieldLimits.positionDescription
    },
    {
        id: 4,
        title: 'Benefits',
        names: ['results', 'benefits'],
        itemRule: {
            max: FieldLimits.positionDescription,
            message: `Benefits cannot be longer than ${FieldLimits.positionDescription} characters`
        },
        placeholder: '',
        max: FieldLimits.positionDescription
    },
    {
        id: 5,
        title: 'Education',
        names: ['results', 'education'],
        itemRule: {
            max: FieldLimits.positionEducation,
            message: `Education cannot be longer than ${FieldLimits.positionEducation} characters`
        },
        placeholder: '',
        max: FieldLimits.positionEducation
    },
    {
        id: 6,
        title: 'Certifications',
        names: ['results', 'certification'],
        itemRule: {
            max: FieldLimits.positionCertification,
            message: `Certifications cannot be longer than ${FieldLimits.positionEducation} characters`
        },
        placeholder: '',
        max: FieldLimits.positionCertification
    },
    {
        id: 7,
        title: 'Suggested Skills',
        names: ['results', 'skills'],
        itemRule: {
            max: FieldLimits.positionSkills,
            message: `Skills cannot be longer than ${FieldLimits.positionSkills} characters`
        },
        placeholder: '',
        max: FieldLimits.positionSkills
    },
    // {id: 8, title: 'Position Title', names: ['results', 'title']},
]

// export const CompanyEditItem: ReviewItemType[] = [
//     {
//         id: 1,
//         title: 'Company Name',
//         names: ['results', 'title'],
//         itemRule: [],
//         placeholder: ''
//     },
//     {
//         id: 2,
//         title: 'Company specification',
//         names: ['results', 'responsibilities'],
//         itemRule: [],
//         placeholder: ''
//     },
//     {
//         id: 3,
//         title: 'Link to the site',
//         names: ['results', 'requirements'],
//         itemRule: [],
//         placeholder: ''
//     },
//     {
//         id: 4,
//         title: 'Phone number',
//         names: ['results', 'requirements'],
//         itemRule: [],
//         placeholder: ''
//     },
//     {
//         id: 5,
//         title: 'Address',
//         names: ['results', 'requirements'],
//         itemRule: [],
//         placeholder: ''
//     },
//     {
//         id: 6,
//         title: 'About Сompany',
//         names: ['results', 'requirements'],
//         itemRule: [],
//         placeholder: ''
//     }
// ]