import {
    getCompaniesAnalyticsOverview,
    getCompanyAnalytics,
    getPositionAnalytics, getPositionsAnalyticsOverview
} from "./model";

class AnalyticsController {
    async getCompaniesAnalyticsOverview() {
        try {
          const { data } = await getCompaniesAnalyticsOverview();
          return data;
        } catch (e) {
          console.log(e);
        }
  }

  async getPositionsAnalyticsOverview() {
        try {
          const { data } = await getPositionsAnalyticsOverview();
          return data;
        } catch (e) {
          console.log(e);
        }
  }

  async getCompanyAnalytics(id: string) {
        try {
          const { data } = await getCompanyAnalytics(id);
          return data;
        } catch (e) {
          console.log(e);
        }
  }

  async getPositionAnalytics(id: string) {
        try {
          const { data } = await getPositionAnalytics(id);
          return data;
        } catch (e) {
          console.log(e);
        }
  }

}
// eslint-disable-next-line
export default new AnalyticsController();
