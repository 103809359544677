import {FC} from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, Typography } from 'antd';

const { Title} = Typography;

interface AbsenceItemsProps {
    link?: string;
    text: string;
    actionText?: string;
}

const AbsenceItems: FC<AbsenceItemsProps> = ({ link, text, actionText }) => {
    return (
        <Card
            style={{
                borderRadius: 8,
                padding: '40px 24px 40px 24px',
                marginTop: 24
            }}
        >
            <Title level={3}>{text}</Title>
            {actionText && link &&
                <NavLink to={link}>
                    <Button size='large' type='primary' style={{borderRadius: 24}}>
                        {actionText}
                    </Button>
                </NavLink>
            }
        </Card>
    );
};

export default AbsenceItems;
