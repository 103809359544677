import {Form, Input, Button, Select} from 'antd';
import CompanyPicture from '../../../components/shared/CompanyPicture/CompanyPicture';
import {CompanyIndustry} from '../../../types';
import s from "./ReviewCompany.module.css";
import {FieldLimits} from "../../../static/staticData";
import parseAddress from "../../../utils/parseAddress";
import {FC} from "react";

interface ReviewCompanyProps {
    editData: any;
    onSave: (editedData: any) => void;
    onCancel: () => void;
}

const ReviewCompany: FC<ReviewCompanyProps> = ({editData, onSave, onCancel}) => {
    const [form] = Form.useForm();

    // Split location string on dict
    const updatedEditData = {...editData};
    updatedEditData.location = parseAddress(updatedEditData.location);

    const formFields = [
        {name: ["location", "city"], title: "City", placeholder: "City"},
        {name: ["location", "state"], title: "State", placeholder: "State"},
        {name: ["location", "zipCode"], title: "Zip Code", placeholder: "Zip Code"}
    ];

    const handleCancel = () => {
        onCancel();
    };

    const onFinish = async (editedData: any) => {
        editedData.location = `${editedData.location.address}, ${editedData.location.city}, ${editedData.location.state}, ${editedData.location.zipCode}`;
        onSave(editedData);
    };

    return (
        <Form
            style={{display: 'grid', gap: 6}}
            className={"edit-container"}
            initialValues={updatedEditData}
            name="editCompanyForm"
            onFinish={onFinish}
            form={form}
        >
            <div className={s.header}>
                Review Business Info
            </div>
            <span className={s.title}>Image</span>
            <div style={{display: 'flex', gap: 16, alignItems: 'center', marginBottom: 16}}>
                <CompanyPicture size={96} thumbnail={editData.thumbnail || ''}/>
                <span>You will be able to change image later</span>
            </div>

            <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Name</span>
                <Form.Item
                    name="name"
                    rules={[
                        {required: true, message: 'Please enter company name!'},
                        {
                            max: FieldLimits.companyName,
                            message: `Name cannot be longer than ${FieldLimits.companyName} characters`
                        },
                    ]}
                >
                     <Input
                        bordered={false}
                        className={s.inputField}
                        translate='yes'
                    />
                </Form.Item>
            </div>

            <div className={s.editItem}>
                <span className={s.title}>Industry</span>
                <Form.Item
                    name="industry"
                    rules={[{required: true, message: 'Please enter industry'}]}
                >
                    <Select
                        bordered={false}
                        placeholder="Select industry"
                        options={Object.values(CompanyIndustry).map((option) => ({
                            label: option,
                            value: option,
                        }))}
                        size="middle"
                        listHeight={420}
                        style={{
                            width: '100%',
                            borderBottom: '1px solid #EAEDF2',
                            borderRadius: 0
                        }}
                    />
                </Form.Item>
            </div>

            <div className={s.editItem}>
                <span className={s.title}>About</span>
                <Form.Item
                    name="description"
                    rules={[
                        {required: true, message: 'Please enter company description!'},
                        {
                            max: FieldLimits.companyDescription,
                            message: `Description cannot be longer than ${FieldLimits.companyDescription} characters`
                        },
                    ]}
                >
                    <Input.TextArea
                        showCount
                        maxLength={FieldLimits.companyDescription}
                        autoSize
                        bordered={false}
                        className={s.inputField}
                    />
                </Form.Item>
            </div>

            <div className={s.editItem}>
                <span className={s.title}>Short Description</span>
                <Form.Item
                    name="short_description"
                    rules={[
                        {required: true, message: 'Please enter company short description!'},
                        {
                            max: FieldLimits.companyShortDescription,
                            message: `Short Description cannot be longer than ${FieldLimits.companyShortDescription} characters`
                        },
                    ]}
                >
                    <Input.TextArea
                        showCount
                        maxLength={FieldLimits.companyShortDescription}
                        autoSize
                        bordered={false}
                        className={s.inputField}
                    />
                </Form.Item>
            </div>

            <div className={s.editItem}>
                <span className={s.title}>Website</span>
                <Form.Item
                    name="link"
                    rules={[
                        {
                            required: true, message: 'Please enter a link!',
                        },
                        {
                            max: FieldLimits.link,
                            message: `Link cannot be longer than ${FieldLimits.link} characters`
                        },
                        {
                            validator: (_, value) => {
                                if (value && !value.startsWith('http')) {
                                    return Promise.reject('Link should start with "http"');
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input
                        bordered={false}
                        className={s.inputField}
                        placeholder="Link to the site"
                    />
                </Form.Item>
            </div>

            <div className={s.editItem}>
                <span className={s.title}>Phone</span>
                <Form.Item
                    name="phone"
                    rules={[
                        {required: true, message: "Please enter phone!"},
                        {
                            max: FieldLimits.phone,
                            message: `Phone number cannot be longer than ${FieldLimits.phone} characters`
                        },
                        {
                            pattern: /^\+?[0-9\s-()]+$/,
                            message: "Please enter valid phone number!",
                        },
                    ]}
                >
                    <Input
                        bordered={false}
                        className={s.inputField}
                        placeholder="Phone Number"

                    />
                </Form.Item>
            </div>

            <div className={s.editItem}>
                <span className={s.title}>Address</span>
                <Form.Item
                    name={["location", "address"]}
                    rules={[
                        {required: true, message: `Please enter address`},
                        {
                            max: FieldLimits.address,
                            message: `Address cannot be longer than ${FieldLimits.address} characters`
                        },
                    ]}
                >
                     <Input
                        bordered={false}
                        className={s.inputField}
                        placeholder="Address"
                    />
                </Form.Item>
            </div>

            <div className={s.editItems}>
                {formFields.map((field) => (
                    <div key={field.name[1]} className={s.editLocation}>
                        <span className={s.title}>{field.title}</span>
                        <Form.Item
                            name={field.name}
                            rules={[{required: true, message: `Please enter ${field.title.toLowerCase()}!`}]}
                        >
                            <Input
                                bordered={false}
                                className={s.inputField}
                                placeholder={field.placeholder}
                            />
                        </Form.Item>
                    </div>
                ))}
            </div>

             <div className={s.editItem}>
                <span className={s.title}>Brand Identity</span>
                <Form.Item
                    name="brand_identity"
                    rules={[
                        {required: true, message: `Please describe your brand identity`},
                        {
                            max: FieldLimits.brandIdentity,
                            message: `Brand identity cannot be longer than ${FieldLimits.brandIdentity} characters`
                        },
                    ]}
                >
                    <Input.TextArea
                        showCount
                        maxLength={FieldLimits.brandIdentity}
                        autoSize
                        bordered={false}
                        className={s.inputField}
                        placeholder="Brand Identity"
                    />
                </Form.Item>
            </div>

            <div style={{display: "flex", gap: 16, justifyContent: "flex-end", marginTop: 24}}>
                <Button type="default"
                        htmlType="button"
                        onClick={handleCancel}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                >
                    Cancel
                </Button>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                    >
                        Save changes
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};

export default ReviewCompany;
