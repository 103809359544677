import {NavLink, NavLinkProps, useNavigate} from 'react-router-dom';
import {Button, Layout, theme} from 'antd';
import clsx from 'clsx';

import SmallLogo from 'assets/images/logo/small-logo.svg';
import {ReactComponent as HomeIcon} from 'assets/images/icons/home.svg';
import {ReactComponent as BuildingIcon} from 'assets/images/icons/building.svg';
import {ReactComponent as SuitcaseIcon} from 'assets/images/icons/suitcase.svg';
import {ReactComponent as ReportsIcon} from 'assets/images/icons/reports.svg';
import {ReactComponent as LogoutIcon} from 'assets/images/icons/logout.svg';
import schooljoy from 'assets/images/logo/schooljoy-logo.png';

import styles from './Sidebar.module.css';
import {useAppSelector} from "../../../hooks/reduxHooks";
import {useActions} from "../../../hooks/useActions";

const {Sider} = Layout;
const {useToken} = theme;

const siderCSS = `
  .ant-layout-sider-children {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: space-between;
  }`;

const StyledNavLink = (props: NavLinkProps) => {
    const {isDrawerCollapsed} = useAppSelector(state => state.mainDuck)
    return (
        <NavLink
            className={({isActive}) =>
                clsx(
                    styles.NavLink,
                    isActive && styles.NavLinkActive,
                    isDrawerCollapsed && styles.NavLinkCollapsed,
                )
            }
            {...props}
        />
    )
}


const Sidebar = () => {
    const {token} = useToken();
    const navigate = useNavigate()
    const {isDrawerCollapsed} = useAppSelector(state => state.mainDuck)
    const {
        isAuthorized,
        user
    } = useAppSelector(state => state.userDuck)
    const {setDrawerState, logOut} = useActions()

    const handleMouseEnter = () => {
        setDrawerState(true)
    };

    const handleMouseLeave = () => {
        setDrawerState(false);
    };

    const handleLogout = () => {
        logOut()
        navigate('/')
    }
    return (
        <>
            {/*<div style={{width: 80, height: "100%"}}></div>*/}
            <Sider
                className={clsx(styles.Sidebar)}
                trigger={null}
                collapsible
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                collapsed={isDrawerCollapsed}
                width={80}
                collapsedWidth={287}
            >
                <style>{siderCSS}</style>
                <div className={styles.Navigation}>
                    <div className={styles.logoContainer}>
                        <img src={SmallLogo} className={styles.HeroLogo} alt='logo'/>
                        {isDrawerCollapsed
                            &&
                            <img src={schooljoy} className={styles.logo}
                                 alt='logo'/>
                        }
                    </div>
                    <StyledNavLink to='/home'>
                        <HomeIcon stroke={token.colorText}/>
                        {isDrawerCollapsed
                            && <span>Home</span>
                        }
                    </StyledNavLink>
                    <StyledNavLink to='/companies'>
                        <BuildingIcon fill={token.colorText}/>
                        {isDrawerCollapsed
                            && <span>Businesses</span>
                        }
                    </StyledNavLink>
                    <StyledNavLink to='/positions'>
                        <SuitcaseIcon fill={token.colorText}/>
                        {isDrawerCollapsed
                            && <span>Positions</span>
                        }
                    </StyledNavLink>
                    <StyledNavLink to='/analytics'>
                        <ReportsIcon fill={token.colorText}/>
                        {isDrawerCollapsed
                            && <span>Analytics</span>
                        }
                    </StyledNavLink>
                    {/* TODO consider using Tabs component */}
                </div>
                {isAuthorized && (
                    <div className={styles.footer}>
                        {isDrawerCollapsed && (
                            <Button
                                onClick={handleLogout}
                                className={styles.logoutBtn}
                                icon={<LogoutIcon/>}
                                type="default"
                            >
                                Log Out
                            </Button>)
                        }
                        <div className={styles.userInfo}>
                            <div
                                style={{
                                    height: 40,
                                    minWidth: 40,
                                    maxWidth: 40,
                                    borderRadius: '50%',
                                    backgroundColor: '#DDE8F9', // --primary-tint
                                    color: '#003595', // --primary-dark
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                            <span
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: 15,
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                }}
                            >
                                {(user && user.email) && user.email[0]}
                            </span>

                            </div>
                            {isDrawerCollapsed && (
                                <div className={styles.info}>
                                    <div>
                                        <p>{user && (user?.first_name ? `${user.first_name} ${user?.last_name}` : user.email)}</p>
                                        <p>{user && user.email}</p>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                )}
            </Sider>
        </>
    );
};

export default Sidebar;
