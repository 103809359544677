import {Button, Form, Input, Typography} from "antd";
import {ReviewItemType} from "../../../../../static/staticData";
import {rules} from "../rules";
import {PlusOutlined} from "@ant-design/icons";
import {PositionSkill} from "../PositionSkill";

const {TextArea} = Input
const {Text} = Typography


export const ReviewItem = (props: ReviewItemType) => {

    const {title, placeholder, names, itemRule, max} = props

    const isTitleField = title === 'Position Title';
    const isSkillsField = title === 'Suggested Skills';

    return (
        <div>
            <Text strong>{title}</Text>
            {!isSkillsField
                ? (
                    <Form.Item name={names} rules={[...rules, itemRule]}>
                        {isTitleField
                            ? (
                                <Input
                                    placeholder={placeholder}
                                    style={{
                                        borderBottom: '1px solid #EAEDF2',
                                        borderRadius: 0,
                                    }}
                                    bordered={false}
                                />
                            )
                            : (
                                <TextArea
                                    showCount
                                    maxLength={max}
                                    placeholder={placeholder}
                                        autoSize
                                        style={{
                                            borderBottom: '1px solid #EAEDF2',
                                            borderRadius: 0,
                                        }}
                                        bordered={false}
                                />
                            )
                        }
                    </Form.Item>
                )
                : (
                    <Form.List name={names}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map((field) => (
                                    <PositionSkill
                                        removeSkillHandler={remove}
                                        key={field.key}
                                        field={field}
                                    />
                                ))}
                                <Button type='link' onClick={() => add()}>
                                    <PlusOutlined/>
                                    Add Skill
                                </Button>
                            </>
                        )}
                    </Form.List>
                )
            }
        </div>
    );
};

