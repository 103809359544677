import { Layout, Typography, theme } from 'antd';
import SchoolJoyLogo from 'assets/images/logo/schooljoy-logo.png';

const { Header } = Layout;
const { Text } = Typography;
const { useToken } = theme;

interface Props {
  logoLabel?: string;
}

const CustomHeader = ({ logoLabel }: Props) => {
  const { token } = useToken();

  return (
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '22px 40px',
        backgroundColor: 'white',
        boxShadow: 'box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10)',
        borderBottom: '1px solid #EAEDF2',
        zIndex: 100,
      }}
    >
      <img src={SchoolJoyLogo} alt='logo' height={24} />
      {logoLabel && (
        <Text
          style={{
            color: token.colorPrimary,
            fontWeight: 500,
            fontSize: 18,
            marginLeft: 10,
          }}
        >
          {logoLabel}
        </Text>
      )}
    </Header>
  );
};

export default CustomHeader;
