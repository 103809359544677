import {
    Card,
    Typography,
    theme,
    Flex,
    Button,
    Dropdown,
    MenuProps,
    Modal,
    Tag
} from 'antd';
import {Company, deleteCompany} from 'features/companies';
import {ReactComponent as LocationIcon} from 'assets/images/icons/location.svg';
import s from './CompanyItem.module.css';
import CompanyPicture from "../../../components/shared/CompanyPicture/CompanyPicture";
import {NavLink, useNavigate} from "react-router-dom";
import {CSSProperties, useState} from "react";
import {MoreOutlined} from "@ant-design/icons";
import styles from "../../CompanySearch/Positions/Positions/PositionCard/PositionCard.module.css";
import {ReactComponent as Edit} from '../../../assets/images/icons/edit.svg';
import {ReactComponent as TrashCan} from '../../../assets/images/icons/trash-can.svg';

const {Text} = Typography;
const {useToken} = theme;

interface Props {
    company: Company;
    style?: CSSProperties;
}

const menuItems: MenuProps['items'] = [
    {
        label: 'Edit',
        key: '1',
        icon: <Edit className={styles['dropdown-icon']}/>,
        style: {width: 240}
    },
    {
        label: 'Delete',
        key: '2',
        icon: <TrashCan className={styles['dropdown-icon']}/>,
        style: {width: 240}
    }
]

const CompanyItem = ({company, ...props}: Props) => {
    const {token} = useToken();
    const navigate = useNavigate();
    const MAX_DESCRIPTION_LENGTH = 250;

    const [deleting, setDeleting] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);


    const description = company.dynamic_descriptions.origin.description
    const truncatedDescription =
        company.dynamic_descriptions && description
            ? description.length > MAX_DESCRIPTION_LENGTH
                ? `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
                : description
            : 'No data';
    const handleMenuClick = (key: string) => {
        if (key === '1') {
            navigate(`/companies/edit/${company.id}`, {state: company});
        } else if (key === '2') {
            setDeleting(true);
        }
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        if (company) {
            try {
                await deleteCompany(company.id);
            } catch (error) {
                console.error("Error deleting company:", error);
            } finally {
                setConfirmLoading(false);
                setDeleting(false);
                window.location.reload();
            }
        }
    };

    const handleCancel = () => {
        setDeleting(false);
    };

    return (
        <Card hoverable className={s.container} style={{cursor: "default"}}>
            <Flex align={"start"} gap={20}>
                <CompanyPicture size={64} thumbnail={company.thumbnail}/>
                <Flex gap={16} vertical={true} style={{width: '100%'}}>
                    <Flex style={{width: '100%'}} justify={'space-between'} vertical={false}>
                        {company.in_process
                            ? (
                                <Text
                                    style={{color: "rgba(102, 112, 133, 1)"}}
                                    className={s.name}
                                >
                                    {company.name}
                                </Text>
                            )
                            : (
                                <NavLink to={`/companies/${company.id}`}>
                                    <Text className={s.name}>{company.name}</Text>
                                </NavLink>
                            )}

                        {company.in_process
                            ? (
                                <Tag color='orange' bordered={false}>
                                    Creating Variations
                                </Tag>
                            )
                            : (
                                <Dropdown.Button
                                    style={{width: 24}}
                                    size='small'
                                    menu={{
                                        items: menuItems,
                                        onClick: ({key}) => handleMenuClick(key as string)
                                    }}
                                    buttonsRender={() => [
                                        null,
                                        <Button type='text'>
                                            <MoreOutlined/>
                                        </Button>,
                                    ]}
                                />
                            )}
                    </Flex>
                    <Text className={s.location}>
                        <LocationIcon fill={token.colorPrimary}/>
                        {company.location}
                    </Text>
                    {!company.in_process &&
                        <Text style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{truncatedDescription}</Text>
                    }
                </Flex>
            </Flex>
            <Modal
                title="Are you sure you want to delete the company?"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                open={deleting}
                onOk={handleOk}
                centered
            >
                <p>All information will be deleted!</p>
            </Modal>
        </Card>
    );
};

export default CompanyItem;
