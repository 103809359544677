import s from "./NavigationActions.module.css";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ButtonLeft, ButtonRight } from "../Layout/Navigation";
import { useAppSelector } from "../../../../../hooks/reduxHooks";
import { useActions } from "../../../../../hooks/useActions";
import { FC } from "react";
import clsx from "clsx";


type Props = {
  percent?: number
}

export const NavigationActions: FC<Props> = (props) => {

  const { percent } = props;
  const { decrementStep, incrementStep } = useActions();

  const {
    step,
    selectedCompany
  } = useAppSelector(state => state.positionDuck);
  const isFirstStep = step === 0;
  const isSecondStep = step === 1;
  const isLastStep = percent === 100;

  return (
      <div className={clsx(s.container, { [s.last]: !isSecondStep, [s.first]: isFirstStep })}>
      {!isFirstStep && (
        <ButtonLeft
          onClick={() => decrementStep()}
          className={s.manual}
          style={{
            borderRadius: 24
          }}
        >
          Back
        </ButtonLeft>
      )}
      {isSecondStep && (
        <Button
          className={s.manual}
          onClick={() => incrementStep()}
          type="default"
          size="large"
          style={{
            borderRadius: 24
          }}
        >
          <PlusOutlined />
          Add Manually
        </Button>
      )}
      <Form.Item>
        <ButtonRight
          // onClick={() => incrementStep()}
          disabled={!selectedCompany}
          htmlType="submit"
          style={{
            borderRadius: 24
          }}
        >
          {isLastStep ? "Create position" : "Next"}
        </ButtonRight>
      </Form.Item>
    </div>
  );
};

