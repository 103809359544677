export enum AuthEndpoint {
  Google = '/google/login',
  Microsoft = '/microsoft/login',
}

export enum AuthCallbackEndpoint {
  Google = '/google/oauth2callback',
  Microsoft = '/microsoft/oauth2callback',
}

export interface AuthLinkResponse {
  url: string;
}

export interface AuthCallbackResponse {
  id: string;
  access_token: string;
  refresh_token: string;
}
