import { Form, Modal } from "antd";
import { JobDetails } from "./JobDetails";
import { AdditionalDetails } from "./AdditionalDetails";
import Preview from "./Preview";
import SelectCompany from "./SelectCompany";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { useActions } from "../../../../hooks/useActions";
import { useEffect, useState } from "react";
import { ReviewAndEdit } from "./ReviewAndEdit";
import PositionsController from "../../../../features/positions/PositionsController";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import s from "./CreateNewPosition.module.css";

const { Text } = Typography;

const initialValues = {
  results: {
    title: "",
    responsibilities: "",
    requirements: "",
    benefits: "",
    education: "",
    certifications: "",
    skills: []
  },
  additionalDetails: {
    type: "",
    age_requirement: false,
    currently_hiring: true,
    high_demand: true
  }
};

const WithNewPositionForm = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const {
    incrementStep,
    resetStep,
    setSelectedCompany
  } = useActions();

  const {
    step,
    selectedCompany
  } = useAppSelector((state) => state.positionDuck);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);


  const currentComponent = [
    <SelectCompany />,
    <JobDetails
      percent={percent}
      setPercent={setPercent}
      isLoading={isLoading}
    />,
    <ReviewAndEdit />,
    <AdditionalDetails />,
    <Preview />
  ];

  const details = async () => {
    setIsLoading(true);
    const title = form.getFieldValue("title");
    const description = form.getFieldValue("description");
    const results = await PositionsController.generatePositionMainData(description);

    form.setFieldValue("results", results);
    form.setFieldValue(["results", "title"], title)

    setPercent(95);

    setTimeout(() => {
      setPercent(100);
      setTimeout(() => {
        setIsLoading(false);
        incrementStep();
      }, 1000);
    }, 1000);
  };

  const createPosition = async () => {
    const values = form.getFieldsValue();
    const payload = {
      ...values.results,
      ...values.additionalDetails
    };
    navigate("/positions");

    await PositionsController.createNewPosition(selectedCompany!.id, payload);
    setSelectedCompany(null);

  };

  const handlers = [
    incrementStep,
    details,
    incrementStep,
    incrementStep,
    () => setShowModal(true),
  ];

  const onFinish = async () => {
    await handlers[step]();
  };

  useEffect(() => {
    return () => {
      setSelectedCompany(null);
      resetStep();
    };
    // eslint-disable-next-line
  }, []);

  return (

    <Form.Provider key="newPosition">
      <Form
        scrollToFirstError
        initialValues={initialValues}
        onFinish={onFinish}
        name="newPosition"
        form={form}
      >
        {currentComponent[step]}
        <Modal
          centered
          title="Creating a position"
          className={s.modal}
          closeIcon={true}
          onCancel={(() => setShowModal(false))}
          okText="Go to Positions"
          okButtonProps={{
            shape: 'round'
          }}
          onOk={createPosition}
          open={showModal}
          footer={(_, { OkBtn }) => (
            <><OkBtn  /></>
          )}
        >
          <Text>
            Creating a position will take about 10
            minutes.Once completed, your card status
            will be changed on the “Position” page.
          </Text>
        </Modal>

      </Form>
    </Form.Provider>
  );
};

export default WithNewPositionForm;