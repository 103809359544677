import { Typography, Input, Form } from "antd";
import NewPositionLayout from "../../components/Layout";
import { NavigationActions } from "../../components/NavigationActions";
import { rules } from "../rules";
import { FC, useEffect } from "react";
import { LinearProgress } from "../../../../../components/shared/LinearProgress/LinearProgress";

const { Title, Text } = Typography;
const { TextArea } = Input;

const textareaPlaceholder =
  "Please copy and paste the job description of the position here. We will use the information to automatically fill out the fields you need to create a position.";

type Props = {
  isLoading: boolean;
  percent: any;
  setPercent: (arg: number) => void;
};

export const JobDetails: FC<Props> = (props) => {

  const {
    percent,
    setPercent,
    isLoading
  } = props;

  useEffect(() => {
    return () => {
      setPercent(0);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <NewPositionLayout
      navigationElements={<NavigationActions />}
      progress={40}
    >
      {isLoading
        ? (
          <LinearProgress
            setPercent={setPercent}
            percent={percent}
          />
        )
        : (
          <>
            <Title level={2} style={{ fontWeight: "bold" }}>
              Provide the job details and description.
            </Title>

            <Text strong> Position Title </Text>
            <Form.Item name="title" validateFirst rules={rules}>
              <Input
                  style={{
                    borderRadius: 0,
                    borderBottom: '1px solid #EAEDF2',
                  }}
                  bordered={false}
                  placeholder="Use adds position title"
              />
            </Form.Item>

            <Text strong> Job Description </Text>
            <Form.Item name="description" validateFirst rules={rules}>
              <TextArea
                style={{
                  height: 330,
                  maxHeight: 500,
                  resize: "none",
                  padding: 10,
                  borderBottom: '1px solid #EAEDF2',
                  borderRadius: 0,
                }}
                bordered={false}
                autoSize
                placeholder={textareaPlaceholder}
              />
            </Form.Item>
          </>
        )}
    </NewPositionLayout>
  );
};
