import { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#0b7cda',
    colorInfo: '#0b7cda',
    colorSuccess: '#00a369',
    colorWarning: '#eba000',
    colorError: '#e41a1a',
    fontFamily: 'Inter',
    fontSize: 15,
    borderRadius: 4,
    colorText: '#344054',
    colorTextSecondary: '#667085',
    colorBgLayout: 'white',
  },
  components: {
    Typography: {
      fontWeightStrong: 500,
      fontSizeHeading1: 36,
    },
    Button: {
      fontWeight: 500,
    },
    Switch: {
      trackHeight: 20,
      trackMinWidth: 40,
      handleSize: 16,
    },
    Tag: {
      colorSuccess: 'var(--colorSuccess)',
      colorSuccessBg: '#F5FEF8',
      colorWarning: 'var(--colorWarning)',
      colorWarningBg: '#FEFEF5',
      paddingContentHorizontal: 8,
      paddingContentVertical: 4,
      fontSizeSM: 13,
    },
  },
};

export default theme;
