import {Tabs} from 'antd';
import  {FC, memo, useMemo} from "react";
import {TabsData, TabsValue} from "../../../../../static/staticData";
import {FullPosition} from "../../../../../features/positions";
import PositionContent from "../PositionContent/PositionContent";
import s from './PositionTabs.module.css'
import {HeaderLayoutColor} from "../../../../../components/shared/ColorHeader";


type Props = {
    item: FullPosition
    setCurrentColor: (arg: HeaderLayoutColor) => void
    setCurrentVar: (arg: TabsValue) => void
}

const colorsArray = [
    HeaderLayoutColor.blue,
    HeaderLayoutColor.green,
    HeaderLayoutColor.orange,
    HeaderLayoutColor.violet
]

const varsArray = [
    TabsValue.original,
    TabsValue.elementary,
    TabsValue.middle,
    TabsValue.high
]
export const PositionsTabs: FC<Props> = memo((props) => {
    const {item, setCurrentColor, setCurrentVar} = props

    const items = useMemo(() => (
        TabsData.map(el => {
            const label = el.label.endsWith('ool')
                ? el.label.split(' ')[0]
                : el.label.includes('Original')
                    ? 'Origin'
                    : el.label;

            return (
                <Tabs.TabPane key={el.key} tab={el.label}>
                    <PositionContent
                        dynamic_field={item.dynamic_fields[label.toLowerCase() as TabsValue]}
                        label={label.toLowerCase() as TabsValue}
                        ageRequirement={item.age_requirement}
                        type={item.type}
                        company={item.company}
                        origin_title={item.dynamic_fields?.origin?.title}
                    />
                </Tabs.TabPane>
            )
        })
        // eslint-disable-next-line
    ), [])

    const setBackgroundColor = (e: string) => {
        setCurrentColor(colorsArray[+e]);
        setCurrentVar(varsArray[+e]);
    }

    return (
        <Tabs
            defaultValue={TabsValue.original}
            onChange={setBackgroundColor}
            className={s.tab}
            size='small'
            centered
        >
            {items}
        </Tabs>
    );
});