import {Layout, Typography} from "antd";
import Sidebar from "../../../components/shared/Sidebar";
import Header from "../../CompanySearch/Positions/components/Layout/Header";
import AnalyticsChart from "../../../components/Analytics/AnalyticsChart/AnalyticsChart";
import s from "../../Analytics/CompanyAnalytics/CompanyAnalytics.module.css";
import AnalyticsCard from "../../../components/Analytics/AnalyticsCard/AnalyticsCard";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PositionAnalyticsResponse} from "../../../features/analytics";
import AnalyticsController from "../../../features/analytics/AnalyticsController";
import {SpinnerBlock} from "../../../components/shared/SpinnerBlock";

const {Title} = Typography;
const {Content} = Layout;

const PositionAnalytics = () => {
    const {uuid} = useParams();

    const [analytics, setAnalytics] = useState<PositionAnalyticsResponse | null>(null);
    const getAnalytics = async (positionId: string) => {
        try {
            const positionData = await AnalyticsController.getPositionAnalytics(positionId);
            if (positionData) {
                setAnalytics(positionData);
            }
        } catch (error) {
            console.error('Error while getting position analytics', error);
        }
    };

    useEffect(() => {
        if (uuid) {
            getAnalytics(uuid);
        }
    }, [uuid]);


    return (
        analytics
            ? (
                <Layout>
                    <Sidebar/>
                    <Layout>
                        <Header/>

                        <Content style={{height: "calc(100vh - 67px)", overflowY: 'auto'}}>
                            <div className={s.companyAnalytics}>
                                <h3 style={{color: '#0072ff', marginBottom: "-35px"}}>
                                    {analytics.company_name}
                                </h3>
                                <div className={s.titleBlock}>
                                    <Title level={2} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                        {analytics.position_title}
                                    </Title>
                                    <AnalyticsCard
                                        count={analytics.interested_students}
                                        text="Interested Students"
                                    />
                                </div>
                                <AnalyticsChart data={analytics.positions_data} type={"position"}/>
                            </div>
                        </Content>

                    </Layout>
                </Layout>
            )
            : <SpinnerBlock/>
    )
};

export default PositionAnalytics;