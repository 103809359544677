import {theme} from 'antd';
import {ReactComponent as SuitcaseIcon} from 'assets/images/icons/suitcase.svg';
import {ReactComponent as CheckIcon} from 'assets/images/icons/check.svg';
import s from './PositionContent.module.css'
import {FC} from "react";
import {Skills} from "../../components/Skills";
import {Map} from "../../../../../components/shared/MapBlock";
import {TextReflector} from "../../../../../components/shared/TextReflector/";
import {TitleEnum} from "../../../../../components/shared/TextReflector/";
import {TabsValue} from "../../../../../static/staticData";

const {useToken} = theme;

interface PositionContentProps {
    label: TabsValue;
    dynamic_field: {
        title: string;
        requirements: string;
        responsibilities: string;
        benefits: string;
        skills: string[];
        education: string;
        certification: string;
    };
    type: string;
    ageRequirement: boolean;
    company: any;
    origin_title: string;
}

const PositionContent: FC<PositionContentProps> = (props) => {
    const {
        label,
        dynamic_field: {
            title,
            skills,
            responsibilities,
            benefits,
            requirements,
            education,
            certification
        },
        type,
        ageRequirement,
        company: {gps_coordinates, location, dynamic_descriptions},
        origin_title
    } = props

    const {token} = useToken();

    return (
        <div className={s.container}>
            <div className={s['card-header']}>
                <h2 className={s.title}>{label.includes("high") && origin_title ? origin_title : title}</h2>
                <div className={s['job-items']}>
                    {/*//TODO: rename class*/}
                    <div className={s.item}>
                        <SuitcaseIcon
                            fill={token.colorPrimary}
                            width={20}
                            height={20}
                        />
                        <p className={s.description}>
                            {type}
                        </p>
                    </div>
                    <div className={s.item}>
                        {ageRequirement &&
                            <>
                                <CheckIcon
                                    fill={token.colorPrimary}
                                    height={20}
                                    width={20}
                                />
                                <p className={s.description}>
                                    Must be 18 or Older
                                </p>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className={s['consistent-container']}>
                <TextReflector
                    title={TitleEnum.description}
                    content={dynamic_descriptions[label].description}
                />
                <TextReflector
                    title={TitleEnum.responsibilities}
                    content={responsibilities}
                />
                <TextReflector
                    title={TitleEnum.requirements}
                    content={requirements}
                />
                <TextReflector
                    title={TitleEnum.benefits}
                    content={benefits}
                />
                <TextReflector
                    title={TitleEnum.certification}
                    content={certification}
                />
                <TextReflector
                    title={TitleEnum.education}
                    content={education}
                />
                <Skills skills={skills}/>
                { gps_coordinates &&
                    <Map
                        coords={{lat: gps_coordinates.latitude, lng: gps_coordinates.longitude}}
                        location={location}
                    />
                }
            </div>
        </div>
    );
};

export default PositionContent;
