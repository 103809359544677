import {Table, Button} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {useNavigate} from "react-router-dom";
import {PositionsCompanyAnalyticsResponse} from "../../../features/analytics";
import {ArrowRightOutlined} from "@ant-design/icons";
import {FC} from "react";

interface Props {
    data: PositionsCompanyAnalyticsResponse[];
}

const PositionTableCompanyPage: FC<Props> = ({data}) => {
    const navigate = useNavigate();

    const columns: ColumnsType<PositionsCompanyAnalyticsResponse> = [
        {
            title: 'Position Title',
            dataIndex: 'position_title',
            key: 'position_title',
            sorter: (a, b) => a.position_title.localeCompare(b.position_title),
        },
        {
            title: 'Interested',
            dataIndex: 'interesting',
            key: 'interesting',
            sorter: (a, b) => a.interesting - b.interesting,
        },
        {
            title: 'Very Interested',
            dataIndex: 'very_interesting',
            key: 'very_interesting',
            sorter: (a, b) => a.very_interesting - b.very_interesting,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            sorter: (a, b) => a.total - b.total,
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Button
                    onClick={() => navigate(`/analytics/positions/${record.position_id}`)}
                    type="link"
                >
                    View More
                    <ArrowRightOutlined />
                </Button>
            ),
        },
    ];

    return (
        <Table
            size='middle'
            style={{backgroundColor: '#fff', marginTop: 48}}
            columns={columns}
            dataSource={data}
            pagination={data && data.length > 10 ? { position: ["bottomLeft"] } : false}
            rowKey={(record) => record.position_id.toString()}
        />
    )
};

export default PositionTableCompanyPage;
