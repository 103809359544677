import {FC} from "react";
import s from './ColorHeader.module.css'
import clsx from "clsx";
export enum HeaderLayoutColor  {
    blue = 'blue',
    orange = 'orange',
    green = 'green',
    violet = 'violet',
    purple = 'purple',
    salad = 'salad'
}

type Props = {
    color: HeaderLayoutColor
    height?: string | number
}
export const ColorHeader:FC<Props> = (props) => {
    const {color, height} = props
    return (
        <div className={clsx(s[color], s.container)} style={{height}}/>
    );
};

