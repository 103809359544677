import {Bar} from 'react-chartjs-2';
import 'chart.js/auto';
import {AnalyticsTable} from "../../../features/analytics";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {FC} from "react";

interface ChartProps {
    data: AnalyticsTable[];
    type: "company" | "position";
}

const createDataset = (label: string, data: number[], backgroundColor: string) => {
    return {
        label: label,
        data: data,
        backgroundColor: backgroundColor,
    }
}

const createChartData = (gradeData: string[], veryInterestingData: number[], interestingData: number[], type: string) => {
    if (type === "company") {
        const totalStudentsData = veryInterestingData.map((value, index) => value + interestingData[index]);

        return {
            labels: gradeData,
            datasets: [
                createDataset('Students', totalStudentsData, 'rgba(0, 97, 177, 1)'),
            ]
        };
    } else {
        return {
            labels: gradeData,
            datasets: [
                createDataset('Very Interested', veryInterestingData, 'rgba(0, 97, 177, 1)'),
                createDataset('Interested', interestingData, 'rgba(0, 231, 199, 1)')
            ]
        };
    }
};
const chartOptions = {
    scales: {
        x: {
            title: {
                display: true,
                text: 'GRADE LEVEL'
            },
            stacked: true,
            ticks: {
                display: true,
            },
            grid: {
                display: false,
                font: {
                    family: "Montserrat",
                    size: 12,
                },
            },
        },
        y: {
            border: {
                display: false
            },
            title: {
                display: true,
                text: '# STUDENTS'
            },
            stacked: true,
            max: 100,
            ticks: {
                stepSize: 20,
                callback: (value: any) => `${value}`,
            },
            grid: {
                display: true,
                font: {
                    family: "Montserrat",
                    size: 12,
                },
            },
        },
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 16,
                boxHeight: 16,
                useBorderRadius: true,
                borderRadius: 2
            }
        },
        datalabels: {
            labels: {
                title: {
                    font: {
                        weight: "bold",
                        size: 15
                    }
                }
            },
            color: ((context: any) => {
                const idx = context.datasetIndex
                const color = ['white', 'black']
                return color[idx] || 'transparent'
            }),
            fontWeight: 'bold'
        }
    },
};

const AnalyticsChart: FC<ChartProps> = ({data, type}) => {
    const grade_Data = data.map((item) => `${item.grade_level}`);
    const veryInterestingData = data.map((item) => item.very_interesting);
    const interestingData = data.map((item) => item.interesting);

    return (
        <Bar
            plugins={[ChartDataLabels]}
            // @ts-ignore
            options={chartOptions}
            data={createChartData(grade_Data, veryInterestingData, interestingData, type)}
        />
    );
};

export default AnalyticsChart;
