export interface ParsedAddress {
    address: string;
    city: string;
    state: string;
    zipCode: string;
}

const parseAddress = (address: string): ParsedAddress => {
    const parts = address.split(',').map(part => part.trim());

    const parsedAddress: ParsedAddress = {
        address: '',
        city: '',
        state: '',
        zipCode: ''
    };

    parts.forEach(part => {
        if (/^\d+\s[A-Za-z\d\s]+$/.test(part)) {
            parsedAddress.address = part;
        } else if (/^[A-Za-z\s]{3,}$/.test(part)) {
            parsedAddress.city = part;
        } else if (/^[A-Z]{2}$/.test(part)) {
            parsedAddress.state = part;
        }
    });

    return parsedAddress;
};

export default parseAddress;
