import { api } from "lib/axios";
import {
  AnalyticsCompaniesOverviewResponse,
  AnalyticsPositionsOverviewResponse,
  CompanyAnalyticsResponse,
  PositionAnalyticsResponse,
} from "../types";

export const getCompaniesAnalyticsOverview = () => {
  return api.get<AnalyticsCompaniesOverviewResponse[]>(`analytics/overview/companies`);
};

export const getPositionsAnalyticsOverview = () => {
  return api.get<AnalyticsPositionsOverviewResponse[]>(`analytics/overview/positions`);
};

export const getCompanyAnalytics = (company_id: string) => {
  return api.get<CompanyAnalyticsResponse>(`companies/${company_id}/analytics`);
};

export const getPositionAnalytics = (position_id: string) => {
  return api.get<PositionAnalyticsResponse>(`positions/${position_id}/analytics`);
};