import {useEffect, useState} from 'react';
import { Typography} from 'antd';
import {Company} from 'features/companies';
import NewPositionLayout from '../components/Layout';
import BusinessesCard from '../../BusinessesList/BusinessesCard';
import {NavigationActions} from "../components/NavigationActions";
import {useAppSelector} from "../../../../hooks/reduxHooks";
import {useActions} from "../../../../hooks/useActions";
import CompaniesController from "../../../../features/companies/CompaniesController";

const {Title, Text} = Typography;


const SelectCompany = () => {

    const {setSelectedCompany} = useActions()
    const {selectedCompany} = useAppSelector(state => state.positionDuck)

    const [userCompanies, setUserCompanies] = useState<Company[]>();

    useEffect(() => {
        CompaniesController.getCompanies(0, 10)
          .then((res) => setUserCompanies(res?.companies))
          .catch(e => console.log(e))
    }, []);


    return (
        <NewPositionLayout
            navigationElements={<NavigationActions/>}
            progress={20}
        >
            <div
                style={{
                    display: 'grid',
                    gap: 24,
                }}
            >
                <div>
                    <Title level={2} style={{fontWeight: 'bold'}}>
                        I want to create a position for...
                    </Title>
                    <Text>Choose from existing companies or manually add a new one</Text>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        overflowY: 'auto',
                        height: 'calc(100vh - 320px)',
                        margin: '0 -16px',
                        padding: '8px 16px',
                    }}
                >
                    {userCompanies?.map((company) => (
                        <div
                            onClick={() => setSelectedCompany(company)}
                            key={company.id}
                        >
                            <BusinessesCard
                                is_redirect={false}
                                company={company}
                                max_len={100}
                                style={{
                                    border: `1px solid ${
                                        company.id === selectedCompany?.id
                                            ? '#0B7CDA'
                                            : 'transparent'
                                    }`,
                                    borderRadius: 4,
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </NewPositionLayout>
    );
};

export default SelectCompany;
