import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Company} from "../features/companies";

interface InitialState {
    step: number
    selectedCompany: Company | null
}

const initialState: InitialState = {
    step: 0,
    selectedCompany: null
};

export const {actions, reducer} = createSlice({
    name: 'position',
    initialState,
    reducers: {
        resetStep: (state) => {
            state.step = 0
        },
        incrementStep: (state) => {
            state.step += 1
        },
        decrementStep: (state) => {
            state.step -= 1
        },
        setSelectedCompany: (state, {payload}: PayloadAction<Company | null>) => {
            state.selectedCompany = payload
        }
    },
});