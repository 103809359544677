import { Layout, Tabs } from 'antd';
import Sidebar from '../../components/shared/Sidebar';
import Header from '../CompanySearch/Positions/components/Layout/Header';
import s from './Analytics.module.css';
import CompanyTable from "../../components/Analytics/CompanyTable/CompanyTable";
import PositionTable from "../../components/Analytics/PositionTable/PositionTable";

const {TabPane} = Tabs;
const { Content } = Layout;

const Analytics = () => {

    return (
        <Layout className={s.layout}>
            <Sidebar/>
            <Layout>
                <Header/>
                <Content style={{height: "calc(100vh - 67px)", overflowY: "auto"}}>
                    <div className={s.analyticsContainer}>
                        <div className={s.card}>
                            <span className={s.insight}>Insights</span>
                            <Tabs defaultActiveKey="business" animated>
                                <TabPane tab="Business" key="business">
                                    <CompanyTable/>
                                </TabPane>
                                <TabPane tab="Positions" key="positions">
                                    <PositionTable/>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};
export default Analytics;
