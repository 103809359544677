import { api } from "lib/axios";
import { FullPosition, PositionsFilter, PositionsResponse } from "../types";

export const getPosition = (position_id: string) => {
  return api.get<FullPosition>(`positions/${position_id}`);
};

export const getUserPositions = (skip: number, limit: number, data: any) => {
  return api.post<PositionsResponse>(`companies/positions/search/by-user`, {
    ...data
  }, { params: { skip, limit } }
  )
};

export const getCompanyPositions = (company_id: string) => {
  return api.get<PositionsResponse>(`companies/${company_id}/positions/`);
};

export const getAllPositions = (skip: number, limit: number, data: any) => {
  return api.post<PositionsFilter>(`companies/positions/search/by-params`, {
    skip,
    limit,
    data
  });
};

export const generateMainData = (description: string) => {
  return api.post("/positions/generate/main_data", { description });
};


export const createPosition = (id: string, payload: any) => {
  return api.post(`/companies/${id}/positions/`, payload );
};

export const deletePosition = (id: string) => {
  return api.delete(`/positions/${id}`)
}

export const updatePositionData = (id: string, payload: any) => {
  return api.put(`/positions/${id}`, payload );
};

export const updatePositionGeneratedData = (id: string, payload: any) => {
  return api.put(`/positions/${id}/generated_data`, payload);
}