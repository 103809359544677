import {Form, Tabs} from 'antd';
import {FC, memo, useEffect, useMemo} from "react";
import s from '../../../pages/CompanySearch/Positions/PositionDetails/PositionsTabs/PositionTabs.module.css'
import {TabsData, TabsValue} from "../../../static/staticData";
import {Company} from "../../../features/companies";
import EditCompanyContent from "../EditCompanyContent/EditCompanyContent";
import EditCompanyMainContent from "../EditCompanyContent/EditCompanyMainContent";


type Props = {
    item: Company
}

export const EditCompanyTabs: FC<Props> = memo((props) => {
    const {item} = props
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldValue("result", item);
        // eslint-disable-next-line
    }, []);

    const items = useMemo(() => (
        TabsData.map(el => {
            const label = el.label.endsWith('ool')
                ? el.label.split(' ')[0]
                : el.label.includes('Original')
                    ? 'Origin'
                    : el.label;

            return (
                <Tabs.TabPane key={el.key} tab={el.label}>
                    <EditCompanyContent
                        label={label.toLowerCase() as TabsValue}
                        companyId={item.id}
                        form={form}
                    />
                </Tabs.TabPane>
            )
        })
        // eslint-disable-next-line
    ), [])


    return (
        <Form
            style={{display: "grid", gap: 24, minHeight: "calc(100vh - 64px)"}}
            className="edit-container"
            initialValues={item}
            name="editCompanyForm"
            form={form}>
            <Tabs
                defaultValue={TabsValue.original}
                className={s.tab}
                size='small'
                centered
            >
                {items}
                <Tabs.TabPane key='main' tab="Main Info">
                    <EditCompanyMainContent
                        companyThumbnail={item.thumbnail}
                        companyId={item.id}
                        form={form}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Form>
    );
});