import {Layout} from "antd";
import Sidebar from "../../shared/Sidebar";
import Header from "../../../pages/CompanySearch/Positions/components/Layout/Header";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Company} from "../../../features/companies";
import CompaniesController from "../../../features/companies/CompaniesController";
import {EditCompanyTabs} from "../EditCompanyTabs/EditCompanyTabs";


const {Content} = Layout;

const EditCompany = () => {
    const {uuid} = useParams();

    const [company, setCompany] = useState<Company | null>(null);
    const fetchCompany = async (companyId: string) => {
        try {
            const companyData = await CompaniesController.getCompany(companyId);
            if (companyData) {
                setCompany(companyData);
            }
        } catch (error) {
            console.error('Error while fetching company', error);
        }
    };

    useEffect(() => {
        if (uuid) {
            fetchCompany(uuid);
        }
    }, [uuid]);

    return (
        company && (
            <Layout className="layout">
                <Sidebar/>
                <Layout>
                    <Header borderColor={"#EAEDF2"}/>
                    <Content style={{height: "calc(100vh - 67px)", overflowY: 'auto'}}>
                        <EditCompanyTabs item={company}/>
                    </Content>
                </Layout>
            </Layout>
        )
    );
};

export default EditCompany;
