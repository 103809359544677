import { FC } from "react";
import s from "./CompanyCardComponent.module.css";
import { Company } from "../../../../../features/companies";
import website from "../../../../../assets/images/icons/wesite.svg";
import phone from "../../../../../assets/images/icons/call.svg";
import { ReactComponent as Edit } from "../../../../../assets/images/icons/edit.svg";
import { ReactComponent as Trash } from "../../../../../assets/images/icons/trash-can.svg";
import CompanyPicture from "../../../../../components/shared/CompanyPicture/CompanyPicture";
import { Button } from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {FullPosition} from "../../../../../features/positions";
import {TabsValue} from "../../../../../static/staticData";

interface CompanyCardProps {
  position?: FullPosition;
  company: Company;
  onDelete?: () => void;
  currentVar?: TabsValue;
}

export const CompanyCard: FC<CompanyCardProps> = (props) => {
  const {pathname} = useLocation();

  const { company, currentVar = TabsValue.original, position, onDelete } = props;
  const navigate = useNavigate();

  const handleEdit = () => {
    if (pathname.includes('companies')) {
      navigate(`/companies/edit/${company.id}`, { state: company });
    } else if (position) {
      navigate(`/positions/edit/${position.id}`, { state: position });
    }
  };



  return (
      <>
        <div className={s.container}>
          <div className={s["company-image-container"]}>
            <CompanyPicture size={96} thumbnail={company.thumbnail || ""} />
          </div>
          <div className={s["contacts-main-wrapper"]}>
            <p className={s["company-name"]}>{company.name}</p>
            <div className={s["company-contacts"]}>
              {company.link && (
                  <div className={s.contact}>
                    <div className={s["contact-icon-wrapper"]}>
                      <img src={website} alt="Website logo" />
                    </div>
                    <a href={company.link.startsWith("htt") ? company.link : `https://${company.link}`}
                       target="_blank"
                       rel="noopener noreferrer">
                      Website
                    </a>
                  </div>
              )}

              {company.phone && (
                  <div className={s.contact}>
                    <div className={s["contact-icon-wrapper"]}>
                      <img src={phone} alt="Phone logo" />
                    </div>
                    <a href={`tel:${company.phone}`}>{company.phone}</a>
                  </div>
              )}
            </div>
            <p className={s["company-short-desc"]}> {company.dynamic_descriptions[currentVar].short_description}</p>
            <div className={s.short_description}>
              <div style={{ display: "flex" }}>
                <Button
                    style={{ alignItems: "center", color: "white", display: "flex", fontFamily: "Montserrat, 'sans-serif'" }}
                    onClick={handleEdit}
                    icon={<Edit />}
                    type="text"
                >
                  Edit
                </Button>
                <Button
                    style={{ alignItems: "center", color: "white", display: "flex", fontFamily: "Montserrat, 'sans-serif'" }}
                    onClick={onDelete}
                    icon={<Trash />}
                    type="text"
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};
