import { api } from 'lib/axios';
import storage from 'lib/storage';

import {
  AuthCallbackEndpoint,
  AuthCallbackResponse,
  AuthEndpoint,
  AuthLinkResponse,
} from '../types';

export const ssoAuth = async (code: string) => {
  const endpoint = document.location.search.includes('google')
    ? AuthCallbackEndpoint.Google
    : AuthCallbackEndpoint.Microsoft;

  return api
    .get<AuthCallbackResponse>(`${endpoint}?code=` + code)
    .then((res) => res.data.access_token)
    .then(storage.setToken);
};

export const getAuthLink = (endpoint: string) =>
  api.get<AuthLinkResponse>(endpoint).then((res) => res.data.url);

export const isUserAuthorized = () => {
  return api.get('/auth/me')
}

export const getGoogleAuthLink = () => getAuthLink(AuthEndpoint.Google);
export const getMicrosoftAuthLink = () => getAuthLink(AuthEndpoint.Microsoft);
