import React, {useEffect, useState} from 'react';
import {Table, Button} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {useNavigate} from "react-router-dom";
import {AnalyticsPositionsOverviewResponse} from "../../../features/analytics";
import AnalyticsController from "../../../features/analytics/AnalyticsController";
import {ArrowRightOutlined} from "@ant-design/icons";


const PositionTable: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<AnalyticsPositionsOverviewResponse[]>();
    const [loading, setLoading] = useState(false);

    const getAnalytics = async () => {
        setLoading(true)
        try {
            const analyticsData = await AnalyticsController.getPositionsAnalyticsOverview();
            if (analyticsData) {
                setData(analyticsData);
            }
        } catch (error) {
            console.error('Error while getting analytics', error);
        }
        setLoading(false)
    };

    useEffect( () => {
        getAnalytics()
    }, []);


    const columns: ColumnsType<AnalyticsPositionsOverviewResponse> = [
        {
            title: 'Position Title',
            dataIndex: 'position_title',
            key: 'position_title',
            sorter: (a, b) => a.position_title.localeCompare(b.position_title),
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => a.company_name.localeCompare(b.company_name),
        },
        {
            title: 'Industry',
            dataIndex: 'company_industry',
            key: 'company_industry',
            sorter: (a, b) => a.company_industry.localeCompare(b.company_industry),
        },
        {
            title: 'Interested Students',
            dataIndex: 'interested_students',
            key: 'interested_students',
            sorter: (a, b) => a.interested_students - b.interested_students,
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Button
                    onClick={() => navigate(`/analytics/positions/${record.position_id}`)}
                    type="link"
                >
                    View Insights
                    <ArrowRightOutlined />
                </Button>
            ),
        },
    ];

     return <Table
            columns={columns}
            dataSource={data}
            pagination={data && data.length > 10 ? { position: ["bottomLeft"] } : false}
            rowKey={(record) => record.position_id.toString()}
            loading={loading}
        />
};

export default PositionTable;
