import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Input,
  Flex,
  Button,
  Row,
  Col,
  Pagination,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import Sidebar from "components/shared/Sidebar";
import PositionCard from "./PositionCard";
import { useNavigate } from "react-router-dom";
import { FullPosition } from "../../../../features/positions";
import PositionsController from "../../../../features/positions/PositionsController";
import { SpinnerBlock } from "../../../../components/shared/SpinnerBlock";
import { useDebounce } from "../../../../hooks/useDebounce";
import AbsenceItems from "../../../../components/shared/AbsenceItems/AbsenceItems";
import Header from "../components/Layout/Header";
import {ColorHeader, HeaderLayoutColor} from "../../../../components/shared/ColorHeader";

const { Content } = Layout;
const { Title } = Typography;

const containerStyle: React.CSSProperties = {
  minHeight: "100vh",
};

const contentStyle: React.CSSProperties = {
  color: "#fff",
  backgroundColor: "white",
  padding: "64px 36px",
  maxHeight: "100vh",
  overflowY: "auto",
  height: "calc(100vh - 67px)"
};

const PositionsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState<FullPosition[] | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const itemsPerPage = 10;

  const debouncedValue = useDebounce(searchValue);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await PositionsController.getUserPositions(
          (currentPage - 1) * itemsPerPage,
          itemsPerPage,
          { position_title: debouncedValue, location: [] }
      );
      response && setPositions(response.positions);
      response && setTotalRecords(response.total_records);
      setLoading(false);
    };

    fetchData();
  }, [currentPage, debouncedValue]);

  return (
      <Layout className="layout" style={containerStyle}>
        <Sidebar />
        <Layout>
          <Header color={"#41BD72"}/>
          <Content style={contentStyle}>
              <ColorHeader color={HeaderLayoutColor.salad} height={264} />
            <div
                className='position-container'
                style={{
                  paddingTop: 0,
                    marginTop: -80
                }}
            >
              <Flex justify='space-between' align='center'>
                <Title style={{ color: 'white', fontWeight: 'bold', zIndex: 1 }}>
                  Positions
                </Title>

                <Button
                    onClick={() => navigate('/positions/new')}
                    style={{
                        borderRadius: 24,
                      color: 'white',
                      backgroundColor: 'rgba(255, 255, 255, 0.10)',
                    }}
                    size='large'
                >
                  <PlusOutlined />
                  Create Position
                </Button>
              </Flex>

              <Row gutter={10}>
                <Col span={24}>
                  <Input
                      size='large'
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder='Search'
                      prefix={<SearchOutlined />}
                  />
                </Col>
                {/*<Col span={6}>*/}
                {/*  <Select*/}
                {/*      options={[{ value: 'Location' }]}*/}
                {/*      mode='multiple'*/}
                {/*      showSearch*/}
                {/*      value={['Location']}*/}
                {/*      placeholder='Location'*/}
                {/*      optionFilterProp='children'*/}
                {/*      filterOption={false}*/}
                {/*      tagRender={({ label }) => (*/}
                {/*          <span*/}
                {/*              style={{*/}
                {/*                display: 'flex',*/}
                {/*                alignItems: 'center',*/}
                {/*                gap: 6,*/}
                {/*              }}*/}
                {/*          >*/}
                {/*      {label}*/}
                {/*            <Tag style={{ color: '#0B7CDA' }} color='#DDE8F9'>*/}
                {/*        2*/}
                {/*      </Tag>*/}
                {/*    </span>*/}
                {/*      )}*/}
                {/*      size='large'*/}
                {/*      listHeight={420}*/}
                {/*      style={{*/}
                {/*        width: '100%',*/}
                {/*      }}*/}
                {/*  />*/}
                {/*</Col>*/}
              </Row>

            {!loading ? (
                positions && positions.length > 0 ? (
                    <>
                        {positions.map((position) => (
                            <PositionCard key={position.id} position={position} />
                        ))}
                        {totalRecords > itemsPerPage && (
                            <Pagination
                                current={currentPage}
                                defaultPageSize={itemsPerPage}
                                total={totalRecords}
                                onChange={(page) => setCurrentPage(page)}
                                style={{ marginTop: 20, textAlign: "center" }}
                            />
                        )}
                    </>
                ) : (
                    searchValue === "" ? (
                        <AbsenceItems
                            link={"/positions/new"}
                            text={"You haven’t created a position yet."}
                            actionText={"Create Position"}
                        />
                    ) : (
                        <AbsenceItems text={"No such positions"} />
                    )
                )
            ) : (
                <SpinnerBlock
                    title="Loading"
                    info="Please wait while data is being fetched"
                />
            )}
            </div>
          </Content>
        </Layout>
      </Layout>
  );
};

export default PositionsPage;
