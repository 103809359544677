export interface GeneratedPosition {
  title: string;
  responsibilities: string;
  requirements: string;
  benefits: string;
  skills: string[];
}

export enum PositionType {
  INTERNSHIP = 'Internship',
  PART_TIME = 'Part-Time',
  FULL_TIME = 'Full-Time',
}

export enum PositionDegreeRequirement {
  NO_HIGH_SCHOOL = 'No high school diploma required',
  HIGH_SCHOOL = 'High school diploma/GED',
  ASSOCIATES_DEGREE = 'Associates degree',
  BACHELORS_DEGREE = 'Bachelors degree,',
  MASTER_DEGREE = 'Masters degree',
}

export enum CompanyIndustry {
  AEROSPACE_AND_DEFENCE = 'Aerospace and Defense',
  AGRICULTURE_AND_FARMING = 'Agriculture and Farming',
  AUTOMOTIVE = 'Automotive',
  BANKING_AND_FINANCIAL_SERVICES = 'Banking and Financial Services',
  CONSTRUCTION = "Construction",
  CONSUMER_GOODS_AND_RETAIL = 'Consumer Goods and Retail',
  EDUCATION = "Education",
  ENERGY_AND_UTILITIES = 'Energy and Utilities',
  ENTERTAINMENT_AND_MEDIA = 'Entertainment and Media',
  FOOD_AND_BEVERAGE = 'Food and Beverage',
  GOVERNMENT_AND_PUBLIC_ADMINISTRATION = 'Government and Public Administration',
  HEALTHCARE_AND_PHARMACEUTICALS = 'Healthcare and Pharmaceuticals',
  HOSPITALITY_AND_TOURISM = 'Hospitality and Tourism',
  INFORMATION_TECHNOLOGY = 'Information Technology',
  INSURANCE_AND_SOFTWARE = 'Insurance and Software',
  MANUFACTURING_AND_INDUSTRIAL = 'Manufacturing and Industrial',
  PROFESSIONAL_SERVICES = "Professional Services",
  REAL_ESTATE = 'Real Estate',
  TELECOMMUNICATIONS = 'Telecommunications',
  TRANSPORTATION_AND_LOGISTICS = 'Transportation and Logistics',
}

export interface Details {
  type: PositionType | '';
  degree_requirement: PositionDegreeRequirement | '';
  age_requirement: boolean;
  currently_hiring: boolean;
  high_demand: boolean;
}
