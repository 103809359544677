import React, {FC, useState} from "react";
import {Button, Form, Input, Modal} from "antd";
import {useNavigate} from "react-router-dom";

import s from "../../PositionDetails/EdtiPositionContent/EditPositionContent.module.css";
import {FieldLimits} from "../../../static/staticData";
import CompaniesController from "../../../features/companies/CompaniesController";

interface CompanyContentProps {
    form: any;
    companyId: string;
    label: string;
}

const EditCompanyContent: FC<CompanyContentProps> = (props) => {
    const navigate = useNavigate()
    const {form, companyId, label} = props

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // eslint-disable-next-line
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = async () => {
        navigate(-1);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCancelEditing = () => {
        if (form.isFieldsTouched()) {
            setIsModalOpen(true);
        } else {
            navigate(-1);
        }
    };

    const handleSaveChanges = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldValue("result");
            const {name, industry, link, phone, location, dynamic_descriptions, thumbnail} = values;

            setLoading(true);

            const updatedCompanyData = {
                name,
                thumbnail,
                industry,
                link,
                phone,
                location,
                dynamic_descriptions
            };

            await CompaniesController.updateCompanyById(companyId, updatedCompanyData);
            setLoading(false);
            navigate(-1);

        } catch (errorInfo) {
            console.error("Error while updating company", errorInfo);
        }
    };

    const attributes = [
        {key: "description", label: "About", maxWidth: FieldLimits.companyDescription},
        {key: "short_description", label: "Short Description", maxWidth: FieldLimits.companyShortDescription},
    ];

    return (
        <>
            <div className={s.header}>
                Edit Business Info
            </div>

            {attributes.map((attribute) => (
                <div key={attribute.key}
                     style={{
                         display: "flex",
                         width: "100%",
                         flexDirection: "column",
                         gap: 10
                     }}
                >
                    <span className={s.title}>{attribute.label}</span>
                    <Form.Item
                        name={["result", "dynamic_descriptions", `${label}`, attribute.key]}
                        rules={[
                            {required: true, message: `Please enter variation ${attribute.label.toLowerCase()}!`},
                            {
                                max: attribute.maxWidth,
                                message: `${attribute.label} cannot be longer than ${attribute.maxWidth} characters`
                            },
                        ]}
                    >
                        <Input.TextArea
                            showCount
                            maxLength={attribute.maxWidth}
                            placeholder={`Enter ${attribute.label.toLowerCase()} for ${label}`}
                            autoSize
                            bordered={false}
                            className={s.inputField}
                        />
                    </Form.Item>
                </div>
            ))}

            <div style={{display: "flex", gap: 16, justifyContent: "flex-end", marginTop: 24}}>
                <Button
                    type="default"
                    htmlType="button"
                    onClick={handleCancelEditing}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 24,
                        padding: "10px 16px",
                        height: "40px"
                    }}
                >
                    Cancel
                </Button>
                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="button"
                        onClick={handleSaveChanges}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                    >
                        Save changes
                    </Button>
                </Form.Item>
            </div>
            <Modal
                title="You Have Unsaved Changes"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                open={isModalOpen}
                onOk={handleOk}
                centered
            >
                <p>You have unsaved changes, while editing information. Unsaved changes will be lost.</p>
            </Modal>
        </>
    );
};

export default EditCompanyContent;
