import {FC, useCallback, useState} from 'react';
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    Circle,
    InfoWindow
} from "@react-google-maps/api";
import s from './MapBlock.module.css'
import marker from '../../../assets/images/icons/marker.svg'
import {circleOptions, mapOptions} from "./mapConfigurations";

type Props = {
    coords: {
        lat: number,
        lng: number
    }
    location: string
}

export const Map: FC<Props> = (props) => {

    const {coords: {lat, lng}, location} = props
    const center = {lat, lng};
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBhFrgiJG4RzJgoFemsek0cVGrQ69_oiXk'
    });
    // eslint-disable-next-line
    const [_, setMap] = useState<google.maps.Map | null>(null)
    const [circleRadius] = useState<number>(145)
    const [defaultZoom] = useState<number>(16)
    const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
    const onUnmount = useCallback(() => setMap(null), []);

    return (
        <div>
            {isLoaded && (
                <GoogleMap
                    mapContainerClassName={s.map}
                    onUnmount={onUnmount}
                    options={mapOptions}
                    zoom={defaultZoom}
                    center={center}
                    onLoad={onLoad}
                >
                    <Marker position={center} icon={marker}>
                        <Circle
                            options={circleOptions}
                            radius={circleRadius}
                            center={center}
                        />
                        <InfoWindow position={center}>
                            <div className={s.window}>
                                <div className={s['marker-wrapper']}>
                                    <img
                                        className={s.marker}
                                        src={marker}
                                        alt="marker icon"
                                    />
                                </div>
                                <p className={s.address}>{location}</p>
                            </div>
                        </InfoWindow>
                    </Marker>
                </GoogleMap>
            )}
        </div>
    );
};