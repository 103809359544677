import {Card, Typography, theme, Space, Tag} from "antd";
import {Company} from "features/companies";
import {ReactComponent as LocationIcon} from "assets/images/icons/location.svg";
import styles from "./BusinessesList.module.css";
import {CSSProperties} from "react";
import CompanyPicture from "../../../components/shared/CompanyPicture/CompanyPicture";
import {NavLink} from "react-router-dom";

const {Text} = Typography;
const {useToken} = theme;

interface Props {
    is_redirect: boolean;
    company: Company;
    max_len: number;
    style?: CSSProperties;
}

const BusinessesCard = ({is_redirect, company, max_len, ...props}: Props) => {
    const {token} = useToken();

    const description = company.dynamic_descriptions.origin.description
    const truncatedDescription =
        company.dynamic_descriptions && description
            ? description.length > max_len
                ? `${description.substring(0, max_len)}...`
                : description
            : 'No data';

    const cardContent = (
        <Space size={20}>
            <CompanyPicture size={60} thumbnail={company.thumbnail}/>
            <Space direction="vertical">
                <Text style={{fontSize: 24}}>{company.name}</Text>
                <Text
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 4
                    }}
                >
                    <LocationIcon fill={token.colorPrimary}/>
                    {company.location}
                </Text>
                {!company.in_process && (
                    <Text style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {truncatedDescription}
                    </Text>
                )
                }
            </Space>
        </Space>
    )
    return is_redirect
        ? (
            company.in_process
                ? (
                    <Card
                        className={styles.BusinessesCard}
                        style={{cursor: "default"}}
                        bodyStyle={props.style}
                        hoverable
                    >
                        {cardContent}
                        <Tag
                            style={{
                                position: "absolute",
                                top: 24,
                                right: 24
                            }}
                            bordered={false}
                            color='orange'
                        >
                            Creating Variations
                        </Tag>
                    </Card>
                )
                : (
                    <NavLink to={`/companies/${company.id}`}>
                        <Card
                            className={styles.BusinessesCard}
                            bodyStyle={props.style}
                            hoverable
                        >
                            {cardContent}
                        </Card>
                    </NavLink>
                )
        )
        : (
            <Card
                className={styles.BusinessesCard}
                bodyStyle={props.style}
                hoverable
            >
                {cardContent}
            </Card>
        );
};

export default BusinessesCard;
