import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import { Company, deleteCompany, getCompany } from "../../features/companies";
import s from "../CompanySearch/Positions/PositionDetails/PositionView/PositionView.module.css";
import Sidebar from "../../components/shared/Sidebar";
import { Card, Layout, Modal } from "antd";
import Header from "../CompanySearch/Positions/components/Layout/Header";
import {
  ColorHeader,
  HeaderLayoutColor
} from "../../components/shared/ColorHeader";
import { CompanyCard } from "../CompanySearch/Positions/components/CompanyCard";
import CompanyContent from "./CompanyContent/CompanyContent";
import { FullPosition, getCompanyPositions } from "../../features/positions";
import {useActions} from "../../hooks/useActions";
import {isUserAuthorized} from "../../features/auth/model";

const { Content } = Layout;

const CompanyDetails = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company | null>(null);

  const [positions, setPositions] = useState<FullPosition[] | null>(null);

  const [deleting, setDeleting] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const {logIn} = useActions()
  const getInfoAboutUser = async () => {
    try {
      const {data} = await isUserAuthorized()
      logIn(data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getInfoAboutUser()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (uuid) {
      (async function() {
        const company_response = await getCompany(uuid);
        company_response && setCompany(company_response.data);
        const positions_response = await getCompanyPositions(uuid);

        positions_response && setPositions(positions_response.data.positions);
      })();
    }
  }, [uuid]);


  const showDeleteModal = () => {
    setDeleting(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    if (company) {
      try {
        await deleteCompany(company.id);
      } catch (error) {
        console.error("Error deleting company:", error);
      } finally {
        setConfirmLoading(false);
        setDeleting(false);
        navigate("/companies");
      }
    }
  };

  const handleCancel = () => {
    setDeleting(false);
  };

  return (
    <Layout className={s.layout}>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{height: "calc(100vh - 67px)", overflowY: "auto"}}>
          {(company && positions)
            && (<>
              <ColorHeader color={HeaderLayoutColor.blue} />
              <div className="position-container">
                <CompanyCard
                  onDelete={showDeleteModal}
                  company={company}
                />
                <Card className={s.card} bodyStyle={{ padding: 0 }}>
                  <CompanyContent positions={positions} company={company} />
                </Card>
              </div>
            </>)
          }

          <Modal
            title="Are you sure you want to delete the business?"
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            open={deleting}
            onOk={handleOk}
            centered
          >
            <p>All information will be deleted!</p>
          </Modal>

        </Content>
      </Layout>
    </Layout>
  );
};

export default CompanyDetails;
