import React, {FC, useState} from "react";
import {Button, Checkbox, Form, Modal, Select, Switch} from "antd";
import {useNavigate} from "react-router-dom";

import s from "../EdtiPositionContent/EditPositionContent.module.css";
import {PositionType} from "../../../types";
import PositionsController from "../../../features/positions/PositionsController";

interface PositionContentProps {
    form: any;
    positionId: string;
}

const EditPositionAdditionalContent: FC<PositionContentProps> = (props) => {
    const navigate = useNavigate()
    const { form, positionId } = props

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // eslint-disable-next-line
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = async () => {
        navigate(-1);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCancelEditing = () => {
        if (form.isFieldsTouched()) {
            setIsModalOpen(true);
        } else {
            navigate(-1);
        }
    };

    const handleSaveChanges = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldValue("result");

            const { type, age_requirement, currently_hiring, high_demand, dynamic_fields } = values;

            setLoading(true);

            const updatedPositionData = {
                type,
                age_requirement,
                currently_hiring,
                high_demand,
                dynamic_fields,
            };
            await PositionsController.updatePositionData(positionId, updatedPositionData);

            setLoading(false);
            navigate(-1);

        } catch (errorInfo) {
            console.error("Error while updating position", errorInfo);
        }
    };

    const options = Object.values(PositionType)
        .map((option) => ({
            label: option,
            value: option,
        }))

    return (
        <>
            <div className={s.header}>
                Edit Position
            </div>

            <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Position Type</span>
                <Form.Item rules={[]}
                           name={["result", 'type']}>
                    <Select
                        placeholder='Select position type'
                        optionFilterProp='children'
                        options={options}
                        size='large'
                    />
                </Form.Item>
            </div>

            <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Age Requirement</span>
                <Form.Item
                    name={["result", 'age_requirement']}
                    valuePropName='checked'
                    // className={s.inputField}
                >
                    <Checkbox>
                        Students must be 18 and over to apply for this position
                    </Checkbox>
                </Form.Item>
            </div>

            <div className={s['switch-container']}>
                <span className={s.title}>Position Status</span>
                <div className={s.switch}>
                    <Form.Item
                        name={["result", 'currently_hiring']}
                        valuePropName='checked'
                        noStyle
                    >
                        <Switch/>
                    </Form.Item>
                    <span className={s.title}>Currently Hiring</span>
                </div>

                <div className={s.switch}>
                    <Form.Item
                        name={["result", 'high_demand']}
                        valuePropName='checked'
                        noStyle
                    >
                        <Switch/>
                    </Form.Item>
                    <span className={s.title}>High Demand Position</span>
                </div>
            </div>

            <div style={{ display: "flex", gap: 16, justifyContent: "flex-end", marginTop: 24 }}>
                <Button type="default"
                        htmlType="button"
                        onClick={handleCancelEditing}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                >
                    Cancel
                </Button>
                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="button"
                        onClick={handleSaveChanges}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                    >
                        Save changes
                    </Button>
                </Form.Item>
            </div>
            <Modal
                title="You Have Unsaved Changes"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                open={isModalOpen}
                onOk={handleOk}
                centered
            >
                <p>You have unsaved changes, while editing information. Unsaved changes will be lost.</p>
            </Modal>
        </>
    );
};

export default EditPositionAdditionalContent;
