import {
  Typography,
  Card,
  theme,
  Space,
  Tag,
  Flex,
  Dropdown,
  MenuProps,
  Button, Modal,
} from 'antd';

import { MoreOutlined } from '@ant-design/icons';

import { ReactComponent as LocationIcon } from 'assets/images/icons/location.svg';
import { ReactComponent as SuitcaseIcon } from 'assets/images/icons/suitcase.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/images/icons/check-mark.svg';

import styles from './PositionCard.module.css';
import { useNavigate } from 'react-router-dom';
import {deletePosition, FullPosition} from '../../../../../features/positions';

import {ReactComponent as Edit} from '../../../../../assets/images/icons/edit.svg';
import {ReactComponent as TrashCan} from '../../../../../assets/images/icons/trash-can.svg';
import {useState} from "react";


const { Title, Text, Paragraph } = Typography;

const { useToken } = theme;

const menuItems: MenuProps['items'] = [
  {
    label: 'Edit',
    key: '1',
    icon: <Edit className={styles['dropdown-icon']}/>,
    style: {width: 240}
  },
  {
    label: 'Delete',
    key: '2',
    icon:  <TrashCan className={styles['dropdown-icon']}/>,
    style: {width: 240}
  }
]

interface Props {
  position: FullPosition;
}

const PositionCard = ({ position }: Props) => {
  const { token } = useToken();
  const navigate = useNavigate();

  const [deleting, setDeleting] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    if (position) {
      try {
        await deletePosition(position.id);
      } catch (error) {
        console.error("Error deleting position:", error);
      } finally {
        setConfirmLoading(false);
        setDeleting(false);
        window.location.reload();
      }
    }
  };

  const handleCancel = () => {
    setDeleting(false);
  };


  const handleMenuClick = (key: string) => {
    if (key === '1') {
      navigate(`/positions/edit/${position.id}`);
    } else if (key === '2') {
      setDeleting(true);
    }
  };


  const MAX_DESCRIPTION_LENGTH = 250;

  const truncatedDescription =
      position.dynamic_fields.origin.responsibilities?.length > MAX_DESCRIPTION_LENGTH
          ? `${position.dynamic_fields.origin.responsibilities?.substring(0, MAX_DESCRIPTION_LENGTH)}...`
          : position.dynamic_fields.origin.responsibilities;

  return (
    <Card
      hoverable
      style={{
        marginTop: 24,
        borderRadius: 8,
        boxShadow: '0px 4px 18px 0px rgba(147, 149, 153, 0.10)',
        cursor: 'default'
      }}
    >
      <Flex justify='space-between' align='flex-start'>
        <Space direction='vertical'>
          <Text
              onClick={() => {
                if (!position.in_process) {
                  navigate(`/companies/${position.company.id}`);
                }
              }}
              strong
              style={{
                color: position.in_process ? 'rgba(84, 152, 254, 0.4)' : 'rgba(11, 124, 218, 1)',
                cursor: position.in_process ? 'not-allowed' : 'pointer',
              }}
          >
            {position.company.name}
          </Text>

          <Title level={4}
                 onClick={() => {
                   if (!position.in_process) {
                     navigate(`/positions/${position.id}`);
                   }
                 }}
                 style={{
                   fontWeight: 'bold',
                   marginTop: 10,
                   color: position.in_process ? 'rgba(102, 112, 133, 1)' : 'rgba(52, 64, 84, 1)',
                   cursor: position.in_process ? 'not-allowed' : 'pointer',
          }}>
            {position.dynamic_fields.origin.title}
          </Title>
        </Space>

        <Space size={0}>
          {position.in_process ? (
            <Tag color='orange' bordered={false}>
              Creating Variations
            </Tag>
          ) : (
            <>
              {position.high_demand && <Tag color='warning'>High Demand</Tag>}
              {position.currently_hiring && <Tag color='success'>Hiring</Tag>}
            </>
          )}
          {!position.in_process &&
            <Dropdown.Button
              size='small'
              menu={{
                items: menuItems,
                onClick: ({ key }) => handleMenuClick(key as string),
              }}
              buttonsRender={() => [
                null,
                <Button type='text'>
                  <MoreOutlined />
                </Button>,
              ]}
            ></Dropdown.Button>
          }
        </Space>
      </Flex>
      { !position.in_process &&
          <>
            <Space
              style={{
                margin: '12px 0 24px',
              }}
              size={[20, 4]}
              wrap
            >
              <Text className={styles.LabelWithIcon}>
                <LocationIcon fill={token.colorPrimary} />
                {position.company.location}
              </Text>
              <Text className={styles.LabelWithIcon}>
                <SuitcaseIcon fill={token.colorPrimary} />
                {position.type}
              </Text>
              {/*<Text className={clsx(styles.LabelWithIcon, styles.limited)}>*/}
              {/*  <BookIcon stroke={token.colorPrimary} />*/}
              {/*  {position.dynamic_fields.origin.education}*/}
              {/*</Text>*/}
              <Text className={styles.LabelWithIcon}>
                { position.age_requirement &&
                  <>
                    <CheckMarkIcon stroke={token.colorPrimary} />
                    Must be 18 or Older
                  </>
                }
              </Text>
            </Space>

            <Paragraph>{truncatedDescription}</Paragraph>
          </>
      }
      <Modal
          title="Are you sure you want to delete the position?"
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          open={deleting}
          onOk={handleOk}
          centered
      >
        <p>All information will be deleted!</p>
      </Modal>
    </Card>
  );
};

export default PositionCard;
