import { Layout } from "antd";
import Sidebar from "../../shared/Sidebar";
import Header from "../../../pages/CompanySearch/Positions/components/Layout/Header";
import {EditPositionsTabs} from "../EditPositionTabs/EditPositionTabs";
import {useEffect, useState} from "react";
import PositionsController from "../../../features/positions/PositionsController";
import {useParams} from "react-router-dom";
import {FullPosition} from "../../../features/positions";


const { Content } = Layout;

const EditPosition = () => {
    const {uuid} = useParams();

    const [position, setPosition] = useState<FullPosition | null>(null);
    useEffect(() => {
        const fetchPosition = async () => {
            try {
                const positionData = await PositionsController.getPosition(uuid || "123");
                if (positionData) {
                    setPosition(positionData);
                }
            } catch (error) {
                console.error('Ошибка при загрузке данных позиции:', error);
            }
        };

        fetchPosition();
        // eslint-disable-next-line
    }, []);

    return (
        position && (
            <Layout className="layout">
                <Sidebar/>
                <Layout>
                    <Header borderColor={"#EAEDF2"}/>
                    <Content style={{height: "calc(100vh - 67px)", overflowY: 'auto'}}>
                        <EditPositionsTabs item={position}/>
                    </Content>
                </Layout>
            </Layout>
        )
    );
};

export default EditPosition;
