import {Form, Tabs} from 'antd';
import React, {FC, memo, useEffect, useMemo} from "react";
import s from '../../../pages/CompanySearch/Positions/PositionDetails/PositionsTabs/PositionTabs.module.css'
import {TabsData, TabsValue} from "../../../static/staticData";
import {FullPosition} from "../../../features/positions";
import EditPositionContent from "../EdtiPositionContent/EditPositionContent";
import EditPositionAdditionalContent from "../EditPositionAdditionalContent/EditPositionAdditionalContent";


type Props = {
    item: FullPosition
}

export const EditPositionsTabs: FC<Props> = memo((props) => {
    const {item} = props
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldValue("result", item);
        // eslint-disable-next-line
    }, []);

    const items = useMemo(() => (
        TabsData.map(el => {
            const label = el.label.endsWith('ool')
                ? el.label.split(' ')[0]
                : el.label.includes('Original')
                    ? 'Origin'
                    : el.label;

            return (
                <Tabs.TabPane key={el.key} tab={el.label}>
                    <EditPositionContent
                        form={form}
                        positionId={item.id}
                        label={label.toLowerCase() as TabsValue}
                    />
                </Tabs.TabPane>
            )
        })
        // eslint-disable-next-line
    ), [])


    return (
        <Form
            style={{ display: "grid", gap: 24, minHeight: "calc(100vh - 64px)" }}
            className={"edit-container"}
            initialValues={item}
            name="editCompanyForm"
            form={form}>
            <Tabs
                defaultValue={TabsValue.original}
                className={s.tab}
                size='small'
                centered
            >
                {items}
                <Tabs.TabPane key={'additional'} tab={"Additional Details"}>
                    <EditPositionAdditionalContent
                        form={form}
                        positionId={item.id}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Form>
    );
});