import { Image } from 'antd';
import { FC } from 'react';
import s from './CompanyPicture.module.css';
import safeImage from '../../../assets/images/icons/safe-image.svg';

interface Props {
  thumbnail?: string;
  size: number;
}

const CompanyItem: FC<Props> = ({ thumbnail, size }: Props) => {
  return (
    <>
        <Image
          className={s.image}
          src={thumbnail || safeImage}
          width={size}
          height={size}
          alt=''
        />
    </>
  );
};

export default CompanyItem;
