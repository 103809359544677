import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Flex, Layout, Pagination, Typography} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import Sidebar from "../../components/shared/Sidebar";
import {ColorHeader, HeaderLayoutColor} from "../../components/shared/ColorHeader";
import Header from "../CompanySearch/Positions/components/Layout/Header";
import {SpinnerBlock} from "../../components/shared/SpinnerBlock";
import {Company} from "../../features/companies";
import CompanyItem from "./CompanyItem/CompanyItem";
import s from "./Companies.module.css";
import clsx from "clsx";
import CompaniesController from "../../features/companies/CompaniesController";
import AbsenceItems from "../../components/shared/AbsenceItems/AbsenceItems";
import {useActions} from "../../hooks/useActions";
import {isUserAuthorized} from "../../features/auth/model";

const {Content} = Layout;
const {Title} = Typography;

const Companies = () => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState<Company[] | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const itemsPerPage = 10;

    const {logIn} = useActions()
    const getInfoAboutUser = async () => {
        try {
            const {data} = await isUserAuthorized()
            logIn(data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getInfoAboutUser()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const response = await CompaniesController.getCompanies(
                (currentPage - 1) * itemsPerPage,
                itemsPerPage
            );
            response && setCompanies(response.companies);
            setTotalRecords(response.total_records);
        };

        fetchData();
    }, [currentPage]);

    return (
        <Layout className="layout">
            <Sidebar/>
            <Layout>
                <Header color={"#7439C1"}/>
                <Content style={{height: "calc(100vh - 67px)", overflowY: "auto"}}>
                    <ColorHeader color={HeaderLayoutColor.purple} height={264}/>
                    <div className={clsx(s.container, "position-container")}>
                        <Flex justify="space-between" align="center" style={{marginBottom: 24}}>
                            <Title style={{color: "white", fontWeight: "bold", zIndex: 1}}>
                                Businesses
                            </Title>
                            <Button
                                onClick={() => navigate("/search")}
                                style={{
                                    borderRadius: 24,
                                    color: "white",
                                    backgroundColor: "rgba(255, 255, 255, 0.10)"
                                }}
                                size="large"
                            >
                                <PlusOutlined/>
                                Claim Business
                            </Button>
                        </Flex>
                        {companies
                            ? (
                                companies.length > 0
                                    ? (companies.map((company) => (
                                            <CompanyItem company={company} key={company.id}/>
                                        ))
                                    )
                                    : (
                                        <AbsenceItems
                                            text={"You haven’t claimed a business yet."}
                                            link={"/search"}
                                            actionText={"Search and Claim My Business"}
                                        />
                                    )
                            )
                            : (
                                <SpinnerBlock
                                    title="Loading"
                                    info="Please wait while data is being fetched"
                                />
                            )}
                        {totalRecords > itemsPerPage && (
                            <Pagination
                                current={currentPage}
                                defaultPageSize={itemsPerPage}
                                total={totalRecords}
                                onChange={(page) => setCurrentPage(page)}
                                style={{marginTop: 20, textAlign: "center"}}
                            />
                        )}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Companies;
