import {
    Button,
    Typography,
    Select,
    Checkbox,
    Switch,
    Input,
    Form,
} from 'antd';

import {PlusOutlined} from '@ant-design/icons';


import NewPositionLayout from '../components/Layout';
import {PositionType} from 'types';
import {ReactComponent as TrashCanIcon} from 'assets/images/icons/trash-can.svg';
import {NavigationActions} from "../components/NavigationActions";
import s from "./PositionSkill/PositionSkill.module.css";

const {TextArea} = Input;
const {Title, Text} = Typography;


const Preview = () => {

    const form = Form.useFormInstance();


    return (
        <NewPositionLayout
            navigationElements={<NavigationActions percent={100}/>}
            progress={100}
        >
            <Title level={2} style={{textAlign: 'center', fontWeight: 'bold'}}>
                {form.getFieldValue(['results', 'title'])}
            </Title>

            <div
                style={{
                    display: 'grid',
                    gap: 24,
                }}
            >
                {/* HACK use hidden, to persist `title`. Otherwise Form will reset `title` value */}
                <Form.Item name={['results', 'title']} hidden>
                    <Input
                        placeholder='Auto-filled Position Name'
                        style={{
                            borderBottom: '1px solid #EAEDF2',
                            borderRadius: 0,
                        }}
                        bordered={false}
                    />
                </Form.Item>

                <div>
                    <Text strong>Responsibilities</Text>
                    <Form.Item name={['results', 'responsibilities']}>
                        <TextArea
                            autoSize
                            style={{
                                borderBottom: '1px solid #EAEDF2',
                                borderRadius: 0,
                            }}
                            bordered={false}
                        />
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Requirements</Text>
                    <Form.Item name={['results', 'requirements']}>
                        <TextArea
                            autoSize
                            style={{
                                borderBottom: '1px solid #EAEDF2',
                                borderRadius: 0,
                            }}
                            bordered={false}
                        />
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Benefits</Text>
                    <Form.Item name={['results', 'benefits']}>
                        <TextArea
                            autoSize
                            style={{
                                borderBottom: '1px solid #EAEDF2',
                                borderRadius: 0,
                            }}
                            bordered={false}
                        />
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Education</Text>
                    <Form.Item name={['results', 'education']}>
                        <TextArea
                            autoSize
                            style={{
                                borderBottom: '1px solid #EAEDF2',
                                borderRadius: 0,
                            }}
                            bordered={false}
                        />
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Certifications</Text>
                    <Form.Item name={['results', 'certification']}>
                        <TextArea
                            autoSize
                            style={{
                                borderBottom: '1px solid #EAEDF2',
                                borderRadius: 0,
                            }}
                            bordered={false}
                        />
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Applicable Skills</Text>

                    <Form.List name={['results', 'skills']}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map((field) => (
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: 'auto min-content',
                                        }}
                                        key={field.key}
                                    >
                                        <Form.Item {...field}>
                                            <Input
                                                style={{
                                                    borderBottom: '1px solid #EAEDF2',
                                                    borderRadius: 0,
                                                }}
                                                bordered={false}
                                            />
                                        </Form.Item>
                                        <div
                                            style={{
                                                width: 40,
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <TrashCanIcon
                                                className={s.icon}
                                                height={20}
                                                width={20}
                                                onClick={() => remove(field.name)}
                                                style={{marginBottom: 24}}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <Button type='link' onClick={() => add()}>
                                    <PlusOutlined/>
                                    Add Skill
                                </Button>
                            </>
                        )}
                    </Form.List>
                </div>

                <Text strong style={{fontSize: '20px', fontWeight: 700}}>Additional Details</Text>

                <div>
                    <Text strong>Position Type</Text>
                    <Form.Item name={['additionalDetails', 'type']}>
                        <Select
                            placeholder='Select position type'
                            optionFilterProp='children'
                            filterOption={false}
                            options={Object.values(PositionType).map((option) => ({
                                label: option,
                                value: option,
                            }))}
                            size='large'
                            listHeight={420}
                            style={{
                                width: '100%',

                                borderBottom: '1px solid #EAEDF2',
                                borderRadius: 0,
                            }}
                            bordered={false}

                        />
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Age Requirement</Text>
                    <Form.Item
                        name={['additionalDetails', 'age_requirement']}
                        valuePropName='checked'
                    >
                        <Checkbox style={{width: '100%'}}>
                            Students must be 18 and over to apply for this position
                        </Checkbox>
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Position Status</Text>

                    <div style={{display: 'flex', alignItems: 'baseline', gap: 8}}>
                        <Form.Item
                            name={['additionalDetails', 'currently_hiring']}
                            valuePropName='checked'
                        >
                            <Switch/>
                        </Form.Item>
                        <Text>Currently Hiring</Text>
                    </div>

                    <div style={{display: 'flex', alignItems: 'baseline', gap: 8}}>
                        <Form.Item
                            name={['additionalDetails', 'high_demand']}
                            valuePropName='checked'
                        >
                            <Switch/>
                        </Form.Item>
                        <Text>High Demand Position</Text>
                    </div>
                </div>
            </div>
        </NewPositionLayout>
    );
};

export default Preview;
