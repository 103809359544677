import s from './CompanyContent.module.css'
import {FC} from "react";
import {Company} from "../../../features/companies";
import {TextReflector, TitleEnum} from "../../../components/shared/TextReflector";
import PositionCard from "../../CompanySearch/Positions/Positions/PositionCard";
import {FullPosition} from "../../../features/positions";
import {Map} from "../../../components/shared/MapBlock";
import {Button, Card} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import Typography from "antd/es/typography";
const { Title } = Typography;

interface CompanyContentProps {
    positions: FullPosition[],
    company: Company
}

const CompanyContent: FC<CompanyContentProps> = (props) => {
    const navigate = useNavigate();

    const {
        positions,
        company
    } = props

    return (
        <div className={s.container}>
            <div className={s['split-container']}>
                <div>
                    <h3 className='text-reflector-details-title'>Opportunities</h3>
                    {positions.length === 0 ? (
                        <Card>
                            <Title level={4}>You haven’t created a position yet.</Title>
                            <Button
                                onClick={() => navigate('/positions/new')}
                                type={'primary'}
                                icon={<PlusOutlined />}
                                size='large'
                                style={{
                                        borderRadius: 24
                                }}
                            >
                                Create Position
                            </Button>
                        </Card>

                    ) : (
                        <>
                            {positions.slice(0, 10).map((position) => (
                                <PositionCard key={position.id} position={position} />
                            ))}
                            {positions.length > 10 && (
                                <p style={{ marginTop: 20, textAlign: "center" }}>
                                    Showing the first 10 positions out of {positions.length}.
                                </p>
                            )}
                        </>
                    )}
                </div>
                <div>
                    <TextReflector
                        title={TitleEnum.description}
                        content={company.dynamic_descriptions.origin.description}
                    />
                    { company.gps_coordinates &&
                        <Map
                            coords={{lat: company.gps_coordinates.latitude, lng: company.gps_coordinates.longitude}}
                            location={company.location}
                        />}
                </div>
            </div>
        </div>
    );
};

export default CompanyContent;
