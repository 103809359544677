import {Typography, Select, Checkbox, Switch, Form} from 'antd';
import NewPositionLayout from '../../components/Layout';
import {PositionType} from 'types';
import {NavigationActions} from "../../components/NavigationActions";
import s from './AdditionalDetails.module.css'
import {rules} from "../rules";
const {Title, Text} = Typography;


export const AdditionalDetails = () => {

    const options = Object.values(PositionType)
        .map((option) => ({
            label: option,
            value: option,
        }))

    return (
        <NewPositionLayout
            navigationElements={<NavigationActions/>}
            progress={80}
        >
            <Title level={2} style={{fontWeight: 'bold'}}>
                Additional Details
            </Title>

            <div className={s.container}>
                <div>
                    <Text strong>Position Type</Text>
                    <Form.Item rules={rules} name={['additionalDetails', 'type']}>
                        <Select
                            placeholder='Select position type'
                            optionFilterProp='children'
                            options={options}
                            size='large'
                            style={{
                                borderBottom: '1px solid #EAEDF2',
                                borderRadius: 0,
                            }}
                            bordered={false}
                        />
                    </Form.Item>
                </div>

                <div>
                    <Text strong>Age Requirement</Text>
                    <Form.Item
                        name={['additionalDetails', 'age_requirement']}
                        valuePropName='checked'
                    >
                        <Checkbox>
                            Students must be 18 and over to apply for this position
                        </Checkbox>
                    </Form.Item>
                </div>

                <div className={s['switch-container']}>
                    <Text className={s.title} strong>Position Status</Text>
                    <div className={s.switch}>
                        <Form.Item
                            name={['additionalDetails', 'currently_hiring']}
                            valuePropName='checked'
                            noStyle
                        >
                            <Switch/>
                        </Form.Item>
                        <Text>Currently Hiring</Text>
                    </div>

                    <div className={s.switch}>
                        <Form.Item
                            name={['additionalDetails', 'high_demand']}
                            valuePropName='checked'
                            noStyle
                        >
                            <Switch/>
                        </Form.Item>
                        <Text>High Demand Position</Text>
                    </div>
                </div>
            </div>
        </NewPositionLayout>
    );
};

