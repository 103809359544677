import  {useEffect, useState} from 'react';
import {Table, Button} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {useNavigate} from "react-router-dom";
import {AnalyticsCompaniesOverviewResponse} from "../../../features/analytics";
import AnalyticsController from "../../../features/analytics/AnalyticsController";
import {ArrowRightOutlined} from "@ant-design/icons";


const CompanyTable = () => {
    const navigate = useNavigate();
    const [
        data,
        setData
    ] = useState<AnalyticsCompaniesOverviewResponse[]>();
    const [loading, setLoading] = useState(false);

    const getAnalytics = async () => {
        setLoading(true)
        try {
            const analyticsData = await AnalyticsController.getCompaniesAnalyticsOverview();
            if (analyticsData) {
                setData(analyticsData);
            }
        } catch (error) {
            console.error('Error while getting analytics', error);
        }
        setLoading(false)
    };

    useEffect(() => {
        getAnalytics()
    }, []);

    const columns: ColumnsType<AnalyticsCompaniesOverviewResponse> = [
        {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => a.company_name.localeCompare(b.company_name),
        },
        {
            title: 'Industry',
            dataIndex: 'company_industry',
            key: 'company_industry',
            sorter: (a, b) => a.company_industry.localeCompare(b.company_industry),
        },
        {
            title: 'Positions',
            dataIndex: 'position_count',
            key: 'position_count',
            sorter: (a, b) => a.position_count - b.position_count,
        },
        {
            title: 'Interested Students',
            dataIndex: 'interested_students',
            key: 'interested_students',
            sorter: (a, b) => a.interested_students - b.interested_students,
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Button
                    onClick={() => navigate(`/analytics/companies/${record.company_id}`)}
                    type="link"
                >
                    View Insights
                    <ArrowRightOutlined />
                </Button>
            ),
        },
    ];


    return (
        <Table
            rowKey={(record) => {
                return record.company_id.toString()
            }}
            pagination={data && data.length > 10 ? { position: ["bottomLeft"] } : false}
            loading={loading}
            columns={columns}
            dataSource={data}
        />
    )
};

export default CompanyTable;
