import {FC, useState} from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    Select,
    Upload
} from "antd";
import {useNavigate} from "react-router-dom";
import s from "../../PositionDetails/EdtiPositionContent/EditPositionContent.module.css";
import {FieldLimits} from "../../../static/staticData";
import CompanyPicture from "../../shared/CompanyPicture/CompanyPicture";
import {CompanyIndustry} from "../../../types";
import CompaniesController from "../../../features/companies/CompaniesController";

interface CompanyContentProps {
    form: any;
    companyId: string;
    companyThumbnail: string;
}

const EditCompanyMainContent: FC<CompanyContentProps> = (props) => {
    const navigate = useNavigate()
    const {form, companyId, companyThumbnail} = props

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // eslint-disable-next-line
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const handleOk = async () => {
        navigate(-1);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCancelEditing = () => {
        if (form.isFieldsTouched()) {
            setIsModalOpen(true);
        } else {
            navigate(-1);
        }
    };

    const handleSaveChanges = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldValue("result");

            const {name, industry, link, phone, location, dynamic_descriptions, thumbnail, brand_identity} = values;

            setLoading(true);

            const updatedCompanyData = {
                name,
                thumbnail,
                industry,
                link,
                phone,
                location,
                dynamic_descriptions,
                brand_identity,
            };
            await CompaniesController.updateCompanyById(companyId, updatedCompanyData);
            // #TODO
            if (file) {
                await CompaniesController.updateCompanyImageById(companyId, file);
            }
            setLoading(false);
            navigate(-1);

        } catch (errorInfo) {
            console.error("Error while updating company", errorInfo);
        }
    };

    const beforeUpload = (file: File) => {
        setFile(file);
        return false;
    };

    return (
        <>
            <div className={s.header}>
                Edit Business Info
            </div>

            <div style={{display: "flex", gap: 16, alignItems: "center", marginBottom: 16}}>
                <CompanyPicture size={96} thumbnail={companyThumbnail || ""}/>
                <Form.Item name={["result", "thumbnail"]}>
                    <Upload beforeUpload={beforeUpload}>
                        <Button style={{borderRadius: 24}}>Upload</Button>
                    </Upload>
                </Form.Item>
            </div>

            <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Name</span>
                <Form.Item
                    name={["result", "name"]}
                    rules={[
                        {required: true, message: "Please enter company name!"},
                        {
                            max: FieldLimits.companyName,
                            message: `Name cannot be longer than ${FieldLimits.companyName} characters`
                        }
                    ]}
                >
                    <Input
                        translate="yes"
                        bordered={false}
                        style={{
                            borderBottom: '1px solid #EAEDF2',
                            borderRadius: 0
                        }}
                    />
                </Form.Item>
            </div>

            <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Industry</span>
                <Form.Item
                    name={["result", "industry"]}
                    rules={[{required: true, message: "Please enter industry"}]}
                >
                    <Select
                        placeholder="Select industry"
                        options={Object.values(CompanyIndustry).map((option) => ({
                            label: option,
                            value: option,
                        }))}
                        size='middle'
                        listHeight={420}
                        bordered={false}
                        style={{
                            borderBottom: '1px solid #EAEDF2',
                            borderRadius: 0,
                            width: '100%',
                        }}

                    />
                </Form.Item>
            </div>

            <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Website</span>
                <Form.Item
                    name={["result", "link"]}
                    rules={[
                        {required: true, message: 'Please enter a link!'},
                        {
                            validator: (_, value) => {
                                if (value && !value.startsWith('http')) {
                                    return Promise.reject('Link should start with "http"');
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input
                        bordered={false}
                        style={{
                            borderBottom: '1px solid #EAEDF2',
                            borderRadius: 0
                        }}
                        placeholder="Link to the site"
                    />
                </Form.Item>
            </div>

            <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Phone</span>
                <Form.Item
                    name={["result", "phone"]}
                    rules={[
                        {required: true, message: "Please enter phone!"},
                        {
                            pattern: /^\+?[0-9\s-()]+$/,
                            message: "Please enter valid phone number!",
                        },
                    ]}
                >
                    <Input
                        placeholder="Phone Number"
                        bordered={false}
                        style={{
                            borderBottom: '1px solid #EAEDF2',
                            borderRadius: 0
                        }}
                    />
                </Form.Item>
            </div>

            <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Address</span>
                <Form.Item
                    name={["result", "location"]}
                >
                    <Input
                        readOnly
                        placeholder="Location"
                        bordered={false}
                        style={{
                            borderBottom: '1px solid #EAEDF2',
                            borderRadius: 0
                        }}
                    />
                </Form.Item>
            </div>

            <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                <span className={s.title}>Brand Identity</span>
                <Form.Item
                    name={["result", "brand_identity"]}
                >
                    <Input.TextArea
                        showCount
                        maxLength={FieldLimits.brandIdentity}
                        autoSize
                        bordered={false}
                        className={s.inputField}
                        placeholder="Brand Identity"
                    />
                </Form.Item>
            </div>


            <div style={{display: "flex", gap: 16, justifyContent: "flex-end", marginTop: 24}}>
                <Button
                    type="default"
                    htmlType="button"
                    onClick={handleCancelEditing}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 24,
                        padding: "10px 16px",
                        height: "40px"
                    }}
                >
                    Cancel
                </Button>
                <Form.Item>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="button"
                        onClick={handleSaveChanges}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 24,
                            padding: "10px 16px",
                            height: "40px"
                        }}
                    >
                        Save changes
                    </Button>
                </Form.Item>
            </div>
            <Modal
                title="You Have Unsaved Changes"
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                open={isModalOpen}
                onOk={handleOk}
                centered
            >
                <p>You have unsaved changes, while editing information. Unsaved changes will be lost.</p>
            </Modal>
        </>
    );
};

export default EditCompanyMainContent;
