import {Card, Layout} from "antd";
import Sidebar from "../../../components/shared/Sidebar";
import Header from "../../CompanySearch/Positions/components/Layout/Header";
import AnalyticsChart from "../../../components/Analytics/AnalyticsChart/AnalyticsChart";
import AnalyticsCard from "../../../components/Analytics/AnalyticsCard/AnalyticsCard";
import s from "./CompanyAnalytics.module.css";
import React, {useEffect, useState} from "react";
import AnalyticsController from "../../../features/analytics/AnalyticsController";
import {useParams} from "react-router-dom";
import {CompanyAnalyticsResponse} from "../../../features/analytics";
import PositionTableCompanyPage from "../../../components/Analytics/CompanyTable/PositionTableOnCompanyPage";
import {SpinnerBlock} from "../../../components/shared/SpinnerBlock";

const { Content } = Layout;

const CompanyAnalytics = () => {
    const { uuid } = useParams();

    const [analytics, setAnalytics] = useState<CompanyAnalyticsResponse | null>(null);
    const getAnalytics = async (companyId: string) => {
        try {
            const companyData = await AnalyticsController.getCompanyAnalytics(companyId);
            if (companyData) {
                setAnalytics(companyData);
            }
        } catch (error) {
            console.error('Error while getting company analytics', error);
        }
    };
    useEffect(() => {
        if (uuid) {
            getAnalytics(uuid);
        }
    }, [uuid]);

    return (
        analytics ? <Layout>
            <Sidebar />
            <Layout>
                <Header />
                <Content style={{height: "calc(100vh - 67px)", overflowY: "auto"}}>
                    <div className={s.companyAnalytics}>
                        <Card className={s.card} bodyStyle={{ display: "flex", flexDirection: "column", padding: 40, gap: 24 }}>
                            <div className={s.titleBlock}>
                                <span className={s.header}>
                                    {analytics.company_name}
                                </span>
                                <AnalyticsCard count={analytics.interested_students} text={"Total Interested Students"}/>
                            </div>
                            <div>
                                <span className={s.title}>Interested Students</span>
                            </div>
                            <AnalyticsChart data={analytics.company_data} type={"company"}/>
                            <PositionTableCompanyPage data={analytics.positions}/>
                        </Card>
                    </div>
                </Content>
            </Layout>
        </Layout> : <SpinnerBlock />
    );
};

export default CompanyAnalytics;
