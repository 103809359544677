import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  getGoogleAuthLink,
  getMicrosoftAuthLink,
  ssoAuth,
} from 'features/auth/model';

const useSsoAuth = () => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const [googleAuthLink, setGoogleAuthLink] = useState('');
  const [microsoftAuthLink, setMicrosoftAuthLink] = useState('');

  useEffect(() => {
    getGoogleAuthLink().then(setGoogleAuthLink);
    getMicrosoftAuthLink().then(setMicrosoftAuthLink);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const code = params.get('code');

    if (!code) return;

    ssoAuth(code).then(() => {
      setSearchParams({});
      navigate('/home');
    });
  }, [navigate, setSearchParams]);

  return {
    authLinks: { google: googleAuthLink, microsoft: microsoftAuthLink },
  };
};

export default useSsoAuth;
