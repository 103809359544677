import { Button, ButtonProps } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

export const ButtonLeft = ({ children, ...props }: ButtonProps) => (
  <Button type='primary' size='large' {...props}>
    <ArrowLeftOutlined />
    {children}
  </Button>
);

export const ButtonRight = ({ children, ...props }: ButtonProps) => (
  <Button type='primary' size='large' {...props}>
    {children}
    <ArrowRightOutlined />
  </Button>
);
