import {generateCompany, getCompany, getUserCompanies, updateCompany, updateCompanyImage} from "./model";
import {RegisterCompanyPayload} from "./types";

class CompaniesController {

  async getCompanies(skip: number, limit = 10) {
    try {
      const {data} = await getUserCompanies(skip, limit)
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  async updateCompanyById(id: string, company: any) {
    try {
      const {data} = await updateCompany(id, company)
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  async generateCompany(payload: RegisterCompanyPayload){
    try {
      const {data} = await generateCompany(payload)
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  async updateCompanyImageById(id: string, file: any) {
    try {
      const {data} = await updateCompanyImage(id, file)
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async getCompany(id: string) {
    try {
      const {data} = await  getCompany(id)
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}

// eslint-disable-next-line
export default new CompaniesController();