import {
  getPosition,
  getAllPositions,
  generateMainData,
  createPosition,
  getUserPositions,
  deletePosition, updatePositionData, updatePositionGeneratedData
} from "./model";
import {UserPositionsFilter} from "./types";

class PositionsController {
  async getPosition(id: string) {
    try {
      const { data } = await getPosition(id);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async getUserPositions(skip: number, limit: number, filters_data: UserPositionsFilter) {
    try {
      const { data } = await getUserPositions(skip, limit, filters_data);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async getAllPositions(skip: number, limit: number, filters_data: any) {
    try {
      const { data } = await getAllPositions(skip, limit, filters_data);
      return data.positions;
    } catch (e) {
      console.log(e);
    }
  }

  async generatePositionMainData(description: string) {
    try {
      const { data } = await generateMainData(description);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async createNewPosition(id: string, payload: any) {
    try {
      const { data } = await createPosition(id, payload);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async deletePosition(id: string) {
    try {
      const { data } = await deletePosition(id);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async updatePositionData(id: string, payload: any) {
    try {
      const { data } = await updatePositionData(id, payload);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async updatePositionGeneratedData(id: string, payload: any) {
    try {
      const { data } = await updatePositionGeneratedData(id, payload);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

}
// eslint-disable-next-line
export default new PositionsController();
