import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Pagination, Space, Typography } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { Company } from "features/companies";
import BusinessesCard from "./BusinessesCard";
import { SpinnerBlock } from "../../../components/shared/SpinnerBlock";
import CompaniesController from "../../../features/companies/CompaniesController";
import AbsenceItems from "../../../components/shared/AbsenceItems/AbsenceItems";

const { Title } = Typography;

const BusinessesList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userCompanies, setUserCompanies] = useState<Company[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await CompaniesController.getCompanies(
          (currentPage - 1) * itemsPerPage,
          itemsPerPage
      );
      if (response) {
        setUserCompanies(response.companies);
        setTotalCount(response.total_records);
      }
      setLoading(false);
    };

    fetchData();
  }, [currentPage, itemsPerPage]);

  return (
      <>
        {loading ? (
            <SpinnerBlock />
        ) : (
            <div>
              <Flex justify="space-between" align="center" style={{ marginTop: 40 }}>
                <Title level={3}>Your Businesses ({totalCount})</Title>
                <Button
                    style={{ borderRadius: 24}}
                    onClick={() => navigate("/search")}
                    type="primary"
                    size="large"
                >
                  <PlusOutlined />
                  Claim Business
                </Button>
              </Flex>
              <Space.Compact
                  direction="vertical"
                  style={{ width: "100%", gap: 20 }}
              >
                {userCompanies && userCompanies.length > 0 ? (
                    userCompanies.map((company) => (
                        <BusinessesCard is_redirect={true} company={company} key={company.id} max_len={250} />
                    ))
                ) : (
                    <AbsenceItems text={"You haven’t claimed a business yet."} />
                )}
              </Space.Compact>

              {totalCount > itemsPerPage && (<Pagination
                  current={currentPage}
                  defaultPageSize={itemsPerPage}
                  total={totalCount}
                  onChange={(page) => setCurrentPage(page)}
                  style={{display: "flex", justifyContent: "center", marginTop: 20}}
              />
              )}
            </div>
        )}
      </>
  );
};

export default BusinessesList;
