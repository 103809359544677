import { api } from "lib/axios";
import { Company, GoogleSearchCompany, RegisterCompanyPayload } from "../types";

export const getUserCompanies = (skip: number, limit: number) => {
  return api.get("companies/", { params: { skip, limit } });
};

export const searchCompanies = async (search: string, location?: string) => {
  let url = `companies/search/serpai?q=${search}`;

  if (location) {
    url += `&location=${encodeURIComponent(location)}`;
  }

  try {
    const response = await api.get<GoogleSearchCompany[]>(url);
    return response.data;
  } catch (error) {
    console.error('Error during searchCompanies:', error);
    return [];
  }
};

export const getCompany = (company_id: string) => {
  return api.get<Company>(`companies/${company_id}`);
};

export const deleteCompany = (company_id: string) => {
  return api.delete(`companies/${company_id}`);
};

export const registerNewCompany = (company: any) => {
  return api.post<Company>("companies/", company);
};

export const updateCompany = (id: string, payload: Company) => {
  return api.put(`/companies/${id}`, payload);

};

export const generateCompany = (payload: RegisterCompanyPayload) => {
  return api.post(`/companies/generate/description`, payload);

};

export const updateCompanyImage = (id: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.put(`/companies/${id}/update-image/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};

export const autocompleteCity = (input: string) => {
  return api.get<[]>(`/autocomplete_city?input=${input}`);
};
