import React from 'react';
import s from './AnalyticsCard.module.css';

interface AnalyticsCardProps {
    count: number;
    text: string;
}

const AnalyticsCard: React.FC<AnalyticsCardProps> = ({ count, text }) => {
    return (
        <div className={s.container}>
            <span className={s.header}>{count}</span>
            <span className={s.text}>{text}</span>
        </div>
    );
};

export default AnalyticsCard;
